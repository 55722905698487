import React, { FC, useEffect, useRef, useState } from "react"
import {
  AssetRegistryPublishingShareHolder,
  AssetRegistryTrackShareHolder,
  LCReleaseForm,
  LCReleaseFormData,
  RotorVideoOption,
  SelectOption,
  TrackRegistryConsentFile
} from "../../../../../types"
import "../../styles/Catalog.css"
import Tooltip from "../../../../ui/Tooltip"
import { InfoIcon } from "../../../../ui/icons"
import { SearchSelect } from "../../../../ui/form/select/SearchSelect"
import { Trash, X } from "heroicons-react"
import { Input } from "../.././../../ui/form/Input"
import Button from "../../../../ui/form/Button"
import {
  errorFilledToastOptions,
  ROLE_NAMES,
  ROLES,
  successFilledToastOptions,
  tableCellClasses,
  warningFilledToastOptions
} from "../../../../../constants"
import { releases, users } from "../../../../../apis"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { toast } from "react-toastify"
import AudioPlayerRange from "./AudioPlayerRange"
import Loading from "../../../../ui/Loading"
import TrackShareHolderModal from "./TrackShareHolderModal"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import PublishingShareHolderModal from "./PublishingShareHolderModal"
import UploadContributorConsentFileComponent from "../../../../ui/components/UploadContributorConsentFile"
import RotorVideoComponent from "../../../../ui/components/RotorVideoComponent"
import { lang } from "../../../../../constants/languages"
import {
  notAllowedTrackAttributes,
  notAllowedTrackOfferAttributes,
  notAllowedTrackOfferRelationships,
  notAllowedTrackRelationships
} from "../../../../../utility/newReleaseHelpler"
import { RotorVideosProvider, RotorVideosSmartButton } from "@rotorvideos/react"

interface TrackDetailProps {
  releaseForm: LCReleaseForm
  setReleaseForm: (releaseForm: LCReleaseForm) => void
  options: any
  selectedTrack?: LCReleaseFormData
  setSelectedTrack: (selectedTrack?: LCReleaseFormData) => void
  selectedTrackOffer?: LCReleaseFormData
  setSelectedTrackOffer: (selectedTrackOffer?: LCReleaseFormData) => void
  artistOptions: SelectOption[]
  setArtistOptions: (artistOptions: SelectOption[]) => void
  onSearchArtist: (search: string) => void
  selectedArtistList: string[]
  setSelectedArtistList: (selectedArtistList: string[]) => void
  setErrorText: (errorText: string) => void
  lyricsWidget: any
  audioSrcMap: any
  applyArtistToAll: (key: string, values: string[]) => void
  trackShareHolderRoleOptions: KSelectOption[]
  trackShareHolderList: AssetRegistryTrackShareHolder[]
  setTrackShareHolderList: (trackShareHolderList: AssetRegistryTrackShareHolder[]) => void
  publishingShareHolderList: AssetRegistryPublishingShareHolder[]
  setPublishingShareHolderList: (trackShareHolderList: AssetRegistryPublishingShareHolder[]) => void
  isPublishing: boolean
  trackRegistryConsentFileList: TrackRegistryConsentFile[]
  setTrackRegistryConsentFileList: (trackRegistryConsentFileList: TrackRegistryConsentFile[]) => void
  rotorClassicAccessToken?: string
  setRotorClassicAccessToken: (rotorClassicAccessToken?: string) => void
  rotorSpotifyAccessToken?: string
  setRotorSpotifyAccessToken: (rotorSpotifyAccessToken?: string) => void
}

interface Contributor {
  [key: string]: string[]
}

const TrackDetail: FC<TrackDetailProps> = (props) => {
  const {
    releaseForm,
    setReleaseForm,
    selectedTrack,
    setSelectedTrack,
    selectedTrackOffer,
    setSelectedTrackOffer,
    artistOptions,
    setArtistOptions,
    onSearchArtist,
    setSelectedArtistList,
    selectedArtistList,
    setErrorText,
    lyricsWidget,
    audioSrcMap,
    applyArtistToAll,
    trackShareHolderRoleOptions,
    trackShareHolderList,
    setTrackShareHolderList,
    publishingShareHolderList,
    setPublishingShareHolderList,
    isPublishing,
    trackRegistryConsentFileList,
    setTrackRegistryConsentFileList,
    rotorClassicAccessToken,
    setRotorClassicAccessToken,
    rotorSpotifyAccessToken,
    setRotorSpotifyAccessToken
  } = props

  const [languageOptions, setLanguageOptions] = useState(props.options.languageOptions)
  const [distributorProductSubGenreOptions, setDistributorProductSubGenreOptions] = useState(
    props.options.distributorProductSubGenreOptions
  )
  const [distributorTrackPriceOptions, setDistributorTrackPriceOptions] = useState(
    props.options.distributorTrackPriceOptions
  )

  const [roleOptions, setRoleOptions] = useState(props.options.roleOptions)
  const [contributors, setContributors] = useState<Contributor>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedTrackShareHolder, setSelectedTrackShareHolder] = useState<AssetRegistryTrackShareHolder | undefined>(
    undefined
  )
  const [selectedPublishingShareHolder, setSelectedPublishingShareHolder] = useState<
    AssetRegistryPublishingShareHolder | undefined
  >(undefined)

  const divRef = useRef(null)

  const resetRoleOptions = () => {
    setRoleOptions(
      props.options.roleOptions?.map((option) => {
        return { value: option, label: option }
      })
    )
  }

  useEffect(() => {
    setLanguageOptions(props.options.languageOptions)
    setDistributorProductSubGenreOptions(props.options.distributorProductSubGenreOptions)
    setDistributorTrackPriceOptions(props.options.distributorTrackPriceOptions)
    resetRoleOptions()
  }, [props.options])

  const updateArtistAndContributors = (productAttributes) => {
    const contributors: Contributor = {}
    let artistList: string[] = []
    if (productAttributes.artist) {
      artistList = artistList.concat(productAttributes.artist)
    }
    Object.entries(productAttributes).forEach(([key, value]) => {
      if (props.options.roleOptions.includes(key) && value) {
        //@ts-ignore
        contributors[key] = value
        //@ts-ignore
        artistList = artistList.concat(value)
      }
    })
    setContributors({ ...contributors })
    setArtistOptions(
      artistList.map((artist: string) => {
        return { value: artist, label: artist }
      })
    )
    setSelectedArtistList([...artistList])
  }

  useEffect(() => {
    if (props.options.roleOptions && selectedTrack) {
      updateArtistAndContributors(selectedTrack.data.attributes)
    }
  }, [props.options.roleOptions, selectedTrack])

  const updateTrack = (attributeName: string, value: any) => {
    if (!selectedTrack) {
      return
    }

    setSelectedTrack({
      ...selectedTrack,
      data: {
        ...selectedTrack.data,
        attributes: {
          ...selectedTrack.data.attributes,
          [attributeName]: value
        }
      }
    })
  }

  const updateTrackMultiple = (attributeName: string, value: any, attributeName2: string, value2: any) => {
    if (!selectedTrack) {
      return
    }

    setSelectedTrack({
      ...selectedTrack,
      data: {
        ...selectedTrack.data,
        attributes: {
          ...selectedTrack.data.attributes,
          [attributeName]: value,
          [attributeName2]: value2
        }
      }
    })
  }

  const updateTrackRelationships = (attributeName: string, value: any) => {
    if (!selectedTrack) {
      return
    }

    setSelectedTrack({
      ...selectedTrack,
      data: {
        ...selectedTrack.data,
        relationships: {
          ...selectedTrack.data.relationships,
          [attributeName]: value
        }
      }
    })
  }

  const onClickApplyComposerAndLyricist = (key: string, selectedTrack?: LCReleaseFormData) => {
    if (!selectedTrack) {
      return
    }

    const values = selectedTrack.data.attributes[key]
    selectedTrack.data.attributes.composer = values
    selectedTrack.data.attributes.lyricist = values
    setSelectedTrack({ ...selectedTrack })
  }

  const onClickArtistDublicate = (key: string, selectedTrack?: LCReleaseFormData) => {
    if (!selectedTrack) {
      return
    }

    const values = selectedTrack.data.attributes[key]
    applyArtistToAll(key, values)
  }

  const updateTrackOfferUsage = (attributeName: string, value: any) => {
    if (!selectedTrackOffer) {
      return
    }

    setSelectedTrackOffer({
      ...selectedTrackOffer,
      data: {
        ...selectedTrackOffer.data,
        attributes: {
          ...selectedTrackOffer.data.attributes,
          usage: {
            ...selectedTrackOffer.data.attributes.usage,
            [attributeName]: value
          }
        }
      }
    })
  }

  const updateTrackOfferRelationships = (attributeName: string, value: any) => {
    if (!selectedTrackOffer) {
      return
    }

    setSelectedTrackOffer({
      ...selectedTrackOffer,
      data: {
        ...selectedTrackOffer.data,
        relationships: {
          ...selectedTrackOffer.data.relationships,
          [attributeName]: value
        }
      }
    })
  }

  const onSearchTextChanged = async (event, options, setOptions) => {
    setOptions(options.filter((option) => option.label.toLowerCase().includes(event.target.value.toLowerCase())))
  }

  const onRoleTextChanged = async (event, options, setOptions) => {
    const alreadySelectedOptions = Object.keys(contributors)
      .filter((contributor) => contributor !== "new")
      .map((contributor) => {
        return { label: contributor, value: contributor }
      })

    setOptions([
      ...options.filter((option) => option.label.toLowerCase().includes(event.target.value.toLowerCase())),
      ...alreadySelectedOptions
    ])
  }

  const tooltip = (width: string, text: string, marginTop: string) => {
    return (
      <Tooltip
        place="bottom"
        disableBackground={true}
        content={
          <p
            style={{
              width,
              padding: 5,
              borderRadius: 10,
              backgroundColor: "rgba(0,0,0,0.8)",
              color: "white"
            }}
          >
            {text}
          </p>
        }
        type="info"
      >
        <InfoIcon className={`w-3.5 h-3.5 ${marginTop} ml-1`} />
      </Tooltip>
    )
  }

  const validatePublishingContributors = (selectedTrack: LCReleaseFormData, key: string, label: string) => {
    if (!(selectedTrack.data.attributes[key]?.length > 0)) {
      setErrorText(`${lang.catalog.asset_registry.tracks_and_assets.error_text.mandatory_field}: ${label}`)
      return false
    }
    if (selectedTrack.data.attributes[key]?.length > 4) {
      setErrorText(lang.catalog.asset_registry.tracks_and_assets.error_text.adding_limit.replace("<param>", label))
      return false
    }

    return true
  }

  const getRotorAccessToken = (isClassic: boolean) => {
    setLoading(true)
    users(`/rotor_authenticate/`, "get")
      .then((resp) => {
        if (resp?.data) {
          if (isClassic) {
            setLoading(false)
            setRotorClassicAccessToken(resp?.data)
          } else {
            setRotorSpotifyAccessToken(resp?.data)
            if (divRef.current) {
              setTimeout(() => {
                setLoading(false)
                //@ts-ignore
                const button = divRef.current.querySelector('button[role="button"]')
                if (button) button.click()
              }, 1000)
            }
          }
        }
      })
      .catch((_err) => {
        setLoading(false)
      })
  }

  const onCloseModalReset = () => {
    setSelectedTrack(undefined)
    setTrackShareHolderList([])
    setPublishingShareHolderList([])
    setRotorClassicAccessToken(undefined)
    setRotorSpotifyAccessToken(undefined)
    setTrackRegistryConsentFileList([])
  }

  const saveTrack = () => {
    if (!selectedTrack || !selectedTrackOffer) {
      toast.error(
        lang.catalog.asset_registry.tracks_and_assets.error_text.error_on_saving_tracks,
        errorFilledToastOptions
      )
      return
    }

    if (!validatePublishingContributors(selectedTrack, "composer", "Composer")) return

    if (isPublishing) {
      if (!(selectedTrack.data.attributes["arranger"]?.length > 0)) {
        toast.warning(
          lang.catalog.asset_registry.tracks_and_assets.success.saved_without_arranger,
          warningFilledToastOptions
        )
      }
    }

    setLoading(true)

    const updatedTracks = releaseForm.tracks.map((track: LCReleaseFormData) => {
      if (track.data.id === selectedTrack.data.id) {
        const trackRelationships = {}
        Object.entries(selectedTrack.data.relationships).forEach(([key, value]) => {
          if (value["data"] && !notAllowedTrackRelationships.includes(key)) {
            trackRelationships[key] = { data: value["data"] }
          }
        })
        const trackAttributes = {}
        Object.entries(selectedTrack.data.attributes).forEach(([key, value]) => {
          if (key === "parental-advisory" || key === "clean") {
            trackAttributes[key] = value || false
          } else if (value && !notAllowedTrackAttributes.includes(key)) {
            trackAttributes[key] = value
          }
        })

        const offerRelationships = { offer: { data: { type: "offers", id: "4306163" } } }
        Object.entries(selectedTrackOffer.data.relationships).forEach(([key, value]) => {
          if (value["data"] && !notAllowedTrackOfferRelationships.includes(key)) {
            offerRelationships[key] = { data: value["data"] }
          }
        })
        const offerAttributes = {}
        Object.entries(selectedTrackOffer.data.attributes).forEach(([key, value]) => {
          if (value && !notAllowedTrackOfferAttributes.includes(key)) offerAttributes[key] = value
        })
        const requestBody = {
          track: {
            data: {
              id: selectedTrack.data.id,
              relationships: trackRelationships,
              attributes: trackAttributes,
              type: "tracks"
            }
          },
          offer: {
            data: {
              id: selectedTrackOffer.data.id,
              relationships: offerRelationships,
              attributes: offerAttributes,
              type: "track-offers"
            }
          }
        }
        setLoading(true)
        releases(`/release/label_camp_track_create/`, "post", requestBody).then((resp) => {
          setLoading(false)
          if (!resp.data.success) {
            setErrorText(JSON.stringify(resp.data.result))
          } else {
            toast.success(
              lang.catalog.asset_registry.tracks_and_assets.success.asset_updated,
              successFilledToastOptions
            )
          }
        })
        return selectedTrack
      }
      return track
    })

    const updatedTrackOffers = releaseForm.track_offers.map((trackOffer: LCReleaseFormData) => {
      if (trackOffer.data?.relationships?.track?.data?.id === selectedTrack.data.id) {
        return selectedTrackOffer
      }
      return trackOffer
    })
    setReleaseForm({
      ...releaseForm,
      tracks: updatedTracks,
      track_offers: updatedTrackOffers
    })
    onCloseModalReset()
  }

  const renderCheckBoxInput = (attributeName: string, displayName: string) => {
    const checked = selectedTrackOffer?.data?.attributes?.usage[attributeName]
    return (
      <div className={"flex mt-2"}>
        <input
          style={{ cursor: "pointer", accentColor: "black" }}
          type="checkbox"
          checked={checked}
          onChange={() => updateTrackOfferUsage(attributeName, !checked)}
        />
        <div className={"ml-4"}>
          <KSpan text={displayName} />
        </div>
      </div>
    )
  }

  const openWidget = (trackId: string, isrc: string, title: string, artist: string, album: string) => {
    if (lyricsWidget && (audioSrcMap[trackId] || selectedTrack?.data?.attributes["preview-url"])) {
      const audioSrc = audioSrcMap[trackId] || selectedTrack?.data?.attributes["preview-url"]
      lyricsWidget.open({
        audio: audioSrc,
        ISRC: isrc,
        title,
        artist,
        album
      })
    } else {
      toast.error(lang.catalog.asset_registry.tracks_and_assets.error_text.missing_audio_file, errorFilledToastOptions)
    }
  }

  const confirmTrackShareHolder = () => {
    releases(`/track-share-holder-invitation/save_not_published/`, "post", selectedTrackShareHolder).then((resp) => {
      if (resp.data) {
        setTrackShareHolderList(resp.data)
      }
    })
    setSelectedTrackShareHolder(undefined)
  }

  const confirmPublishingShareHolder = () => {
    releases(`/publishing-share-holder/save/`, "post", {
      ...selectedPublishingShareHolder,
      product_id: releaseForm.product.data.id
    }).then((resp) => {
      if (resp.data) {
        setPublishingShareHolderList(resp.data)
      }
    })
    setSelectedPublishingShareHolder(undefined)
  }

  const onDeleteTrackShareHolder = (existedShareHolder) => {
    if (!existedShareHolder.id) {
      return
    }

    releases(
      `/track-share-holder-invitation/delete_not_published/?id=${existedShareHolder.id}&isrc=${existedShareHolder.isrc}`,
      "delete"
    ).then((resp) => {
      if (resp.data) {
        setTrackShareHolderList(resp.data)
      }
    })
  }

  const onDeletePublishingShareHolder = (existedShareHolder) => {
    if (!existedShareHolder.id) {
      return
    }

    releases(
      `/publishing-share-holder/delete/?id=${existedShareHolder.id}&isrc=${existedShareHolder.isrc}`,
      "delete"
    ).then((resp) => {
      if (resp.data) {
        setPublishingShareHolderList(resp.data)
      }
    })
  }

  const artistSelection = (isMainArtist: boolean, key: string) => {
    if (!roleOptions) {
      return undefined
    }
    const options = props.options.roleOptions?.map((option) => {
      return { value: option, label: option }
    })

    return (
      <div className={"flex border pb-2 pt-2"} key={key}>
        <div className={"relative"} style={{ width: "35%", borderRight: "1px solid rgb(185, 185, 185)" }}>
          <SearchSelect
            className={"mt-1 border-0"}
            placeholder={lang.catalog.asset_registry.tracks_and_assets.select_genre_placeholder}
            containerStyle={{ border: 0, boxShadow: "none" }}
            inputTextClass="text-[16px]"
            disabledOptions={["artist"]}
            options={isMainArtist ? [{ value: "artist", label: "Main Artist" }] : roleOptions}
            onTextChange={(event) => onRoleTextChanged(event, options, setRoleOptions)}
            value={selectedTrack?.data?.attributes[key] ? key : isMainArtist ? "artist" : ""}
            //@ts-ignore
            onChange={(element: string) => {
              delete contributors[key]
              delete selectedTrack?.data?.attributes[key]
              setContributors({ ...contributors, [element || "new"]: [] })
              updateTrack(element, [])
              resetRoleOptions()
            }}
          />
        </div>
        <div className={"relative"} style={{ width: "50%" }}>
          <SearchSelect
            key={`search-select-${key}`}
            className={"mt-1 border-0"}
            placeholder={lang.catalog.asset_registry.tracks_and_assets.select_artist_placeholder}
            containerStyle={{ border: 0, boxShadow: "none" }}
            inputTextClass="text-[16px]"
            multiple
            options={artistOptions}
            onTextChange={(event) => onSearchArtist(event.target.value)}
            value={selectedTrack?.data?.attributes[key] || []}
            //@ts-ignore
            onChange={(element: string[]) => {
              if (!isMainArtist) {
                setContributors({ ...contributors, [key]: element })
              }
              updateTrack(key, element)
              const artistList = selectedArtistList.concat(element)
              //@ts-ignore
              setArtistOptions(artistOptions.filter((option) => artistList.includes(option.value)))
              setSelectedArtistList([...artistList])
            }}
          />
        </div>
        <div className={"flex items-center justify-center"} style={{ width: "5%" }}>
          <div className="relative">
            <Tooltip
              width={100}
              place="bottom"
              content={
                <p className="asset-registry-span text-white">
                  {lang.catalog.asset_registry.general_information.apply_composer_lyricist_tooltip.replace(
                    "<param>",
                    key
                  )}
                </p>
              }
            >
              <img
                width={22}
                height={22}
                className="mt-1"
                src={"/catalog_icons/lyrics.svg"}
                style={{ cursor: "pointer" }}
                onClick={() => onClickApplyComposerAndLyricist(key, selectedTrack)}
              />
            </Tooltip>
          </div>
        </div>
        <div className={"flex items-center justify-center"} style={{ width: "5%" }}>
          <div className="relative">
            <Tooltip
              width={100}
              place="bottom"
              content={
                <p className="asset-registry-span text-white">
                  {lang.catalog.asset_registry.tracks_and_assets.apply_all_tooltip.replace("<param>", key)}
                </p>
              }
            >
              <img
                className="mt-1"
                src="/catalog_icons/files-plus.svg"
                style={{ cursor: "pointer" }}
                onClick={() => onClickArtistDublicate(key, selectedTrack)}
              />
            </Tooltip>
          </div>
        </div>
        <div className={"flex items-center justify-center"} style={{ width: "5%" }}>
          <Trash
            className="h-10 mt-1 cursor-pointer"
            onClick={() => {
              if (isMainArtist) {
                selectedTrack!!.data.attributes["artist"] = []
              } else {
                delete contributors[key]
                //delete releaseForm.product.data.attributes[key]
                delete selectedTrack?.data?.attributes[key]
              }
              setContributors({ ...contributors })
              setReleaseForm({ ...releaseForm })
              resetRoleOptions()
            }}
          />
        </div>
      </div>
    )
  }

  const renderTrackShareHolders = () => {
    const mainArtists = selectedTrack?.data?.attributes["artist"] || []
    const mainContributors = Object.entries(contributors).flatMap(([key, _]) => {
      return selectedTrack?.data?.attributes[key]
    })

    const existedtTrackShareHolderList = trackShareHolderList.map((trackShareHolder) => trackShareHolder.name)
    const shareHolders = [...new Set([...mainArtists, ...mainContributors, ...existedtTrackShareHolderList])]

    return (
      <div className={"mt-2"}>
        {shareHolders.map((shareHolder) => {
          const existedShareHolder = trackShareHolderList.find((tsh) => tsh.name === shareHolder) || {
            name: shareHolder,
            email: "",
            roles: "",
            share: "",
            isrc: selectedTrack?.data?.attributes["isrc-code"]
          }
          return (
            <div key={`share_holder_${shareHolder}`} className="p-4 border flex justify-between">
              <span style={{ maxWidth: 200, minWidth: 200 }}>{shareHolder}</span>
              <span>{existedShareHolder.share || "0"}%</span>
              <div className="flex">
                <img
                  src="/catalog_icons/edit-line.svg"
                  style={{ cursor: "pointer", marginRight: 12 }}
                  onClick={() => setSelectedTrackShareHolder(existedShareHolder)}
                />
                <img
                  src="/catalog_icons/delete-bin.svg"
                  style={{ cursor: "pointer" }}
                  onClick={() => onDeleteTrackShareHolder(existedShareHolder)}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderPublishingShareHolderList = (shareHolders: string[], roleId: number) => {
    return shareHolders.map((shareHolder: string, _index: number) => {
      const existedShareHolder = publishingShareHolderList.find(
        (psh) => psh.name === shareHolder && psh.role === roleId
      ) || {
        name: shareHolder,
        role: roleId,
        share: "",
        isrc: selectedTrack?.data?.attributes["isrc-code"]
      }
      return (
        <div key={`share_holder_${shareHolder}`} className="p-4 border flex justify-between">
          <div className="flex">
            <span className="text-gray-500" style={{ maxWidth: 200, minWidth: 200 }}>{`${ROLE_NAMES[roleId]} #${
              _index + 1
            }: `}</span>
            <span style={{ maxWidth: 200, minWidth: 200 }}>{shareHolder}</span>
          </div>
          <span>{existedShareHolder.share || "0"}%</span>
          <div className="flex">
            <img
              src="/catalog_icons/edit-line.svg"
              style={{ cursor: "pointer", marginRight: 12 }}
              onClick={() => setSelectedPublishingShareHolder(existedShareHolder)}
            />
            <img
              src="/catalog_icons/delete-bin.svg"
              style={{ cursor: "pointer" }}
              onClick={() => onDeletePublishingShareHolder(existedShareHolder)}
            />
          </div>
        </div>
      )
    })
  }

  const renderPublishingShareHolders = () => {
    const composerList = selectedTrack?.data?.attributes["composer"] || []
    const lyricistList = selectedTrack?.data?.attributes["lyricist"] || []
    const arrangerList = selectedTrack?.data?.attributes["arranger"] || []

    return (
      <div className={"mt-2"}>
        {renderPublishingShareHolderList(composerList, ROLES.COMPOSER)}
        {renderPublishingShareHolderList(lyricistList, ROLES.LYRICIST)}
        {renderPublishingShareHolderList(arrangerList, ROLES.ARRANGER)}
      </div>
    )
  }

  const renderRotorVideosClassic = () => {
    const attributes = selectedTrack?.data?.attributes
    const artworkUrl =
      releaseForm.product.data.attributes["front-cover"] || releaseForm.product.data.attributes["cover-url"]
    if (!selectedTrack || !attributes?.["artist"] || !attributes?.["preview-url"] || !artworkUrl || !attributes.title) {
      return <div />
    }

    return (
      <RotorVideoComponent
        accessToken={rotorClassicAccessToken}
        setAccessToken={setRotorClassicAccessToken}
        artistName={selectedTrack.data.attributes["artist"].join(", ")}
        artworkUrl={artworkUrl}
        audioUrl={selectedTrack.data.attributes["preview-url"]}
        trackName={selectedTrack.data.attributes.title}
        trackId={`${releaseForm.product.data.id}_${selectedTrack.data.id}`}
      />
    )
  }

  const renderRotorVideosSpotify = () => {
    const attributes = selectedTrack?.data?.attributes
    const artworkUrl =
      releaseForm.product.data.attributes["front-cover"] || releaseForm.product.data.attributes["cover-url"]
    if (
      !selectedTrack ||
      !attributes?.["artist"] ||
      !attributes?.["preview-url"] ||
      !artworkUrl ||
      !attributes.title ||
      !rotorSpotifyAccessToken ||
      !releaseForm.product.data.id
    ) {
      return <div />
    }
    const productType = props.options.productTypeOptions.find(
      (option) => releaseForm.product.data.relationships["product-type"]?.data?.id === option.value
    )
    const rotorOptions: RotorVideoOption = {
      authConfig: {
        accessToken: rotorSpotifyAccessToken,
        clientId: "7BNACMCje__PuBLuuamXPSXd8ze1m6sFH28JPNUQbtE"
      },
      availableTracks: [
        {
          id: `${releaseForm.product.data.id}_${selectedTrack.data.id}`,
          providerName: "partner-demo-api",
          artistName: selectedTrack.data.attributes["artist"].join(", "),
          releaseId: releaseForm.product.data.id,
          releaseType: productType,
          releaseName: releaseForm.product.data.attributes["name"],
          releaseArtworkUrl: artworkUrl,
          trackName: selectedTrack.data.attributes.title,
          artworkUrl,
          audioUrl: selectedTrack.data.attributes["preview-url"]
        }
      ]
    }

    return (
      <RotorVideosProvider {...rotorOptions}>
        <RotorVideosSmartButton providerReferenceId={`${releaseForm.product.data.id}_${selectedTrack.data.id}`} />
      </RotorVideosProvider>
    )
  }

  const onClickAutoGenerateVideos = (isClassic: boolean) => {
    const artworkUrl =
      releaseForm.product.data.attributes["front-cover"] || releaseForm.product.data.attributes["cover-url"]

    if (!selectedTrack?.data?.attributes?.title) {
      toast.warning(
        lang.catalog.asset_registry.tracks_and_assets.success.missing_track_title,
        warningFilledToastOptions
      )
    } else if (!selectedTrack?.data?.attributes?.["preview-url"]) {
      toast.warning(lang.catalog.asset_registry.tracks_and_assets.success.missing_audio_file, warningFilledToastOptions)
    } else if (!selectedTrack?.data?.attributes?.["artist"]?.length) {
      toast.warning(lang.catalog.asset_registry.tracks_and_assets.success.missing_artist, warningFilledToastOptions)
    } else if (!artworkUrl) {
      toast.warning(lang.catalog.asset_registry.tracks_and_assets.success.missing_cover_file, warningFilledToastOptions)
    } else {
      getRotorAccessToken(isClassic)
    }
  }

  return (
    <div>
      {loading && <Loading />}
      {selectedTrack && (
        <div
          className="fixed inset-0 flex items-end justify-end h-[100%] overflow-auto"
          style={{ zIndex: 0, background: "rgba(86, 86, 86, 0.57)" }}
        >
          <div className="mt-10 bg-white elev-b-sm overflow-auto h-[100%] w-[60%]">
            <div
              className={`bg-gray-50 text-xs text-gray-500 font-medium 
                uppercase border-b flex justify-between ${tableCellClasses.sm}`}
            >
              <KTitleSpan
                text={`${lang.catalog.asset_registry.tracks_and_assets.track} #${selectedTrack?.data?.attributes["track-number"]}`}
                fontSize={18}
                lineHeight="auto"
              />
              <X className="h-4 mr-[-6px] cursor-pointer" onClick={() => onCloseModalReset()} />
            </div>

            <div className={"bg-gray-100"}>
              <div className={"flex"}>
                <div style={{ width: "100%" }}>
                  <div className={"m-4 bg-white"}>
                    <div className={"p-4"}>
                      <KTitleSpan
                        fontSize={18}
                        lineHeight="auto"
                        text={lang.catalog.asset_registry.tracks_and_assets.title}
                      />
                      <div className={"flex mt-5"}>
                        <div className={"pr-5"} style={{ width: "50%" }}>
                          <div className="flex">
                            <KSpan text={lang.catalog.asset_registry.tracks_and_assets.title} />
                            <span className="ml-0.5" style={{ color: "red" }}>
                              &#42;
                            </span>
                          </div>

                          <Input
                            className={`border mt-1`}
                            placeholder={""}
                            value={selectedTrack?.data?.attributes?.title}
                            onChange={(event) => updateTrack("title", event.target.value)}
                          />

                          <div className={"mt-6"}>
                            <KSpan text={lang.catalog.asset_registry.tracks_and_assets.genre} />
                          </div>
                          <div className={"relative"}>
                            <SearchSelect
                              className={"mt-2"}
                              placeholder={lang.catalog.asset_registry.tracks_and_assets.select_genre_placeholder}
                              inputTextClass="text-[16px]"
                              options={distributorProductSubGenreOptions}
                              onTextChange={(event) =>
                                onSearchTextChanged(
                                  event,
                                  props.options.distributorProductSubGenreOptions,
                                  setDistributorProductSubGenreOptions
                                )
                              }
                              value={selectedTrackOffer?.data.relationships["genre"]?.data?.id}
                              onChange={(element) => {
                                updateTrackOfferRelationships("genre", {
                                  data: { type: "distributor-product-subgenres", id: element }
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ width: "50%" }} className="relative">
                          <div className={"flex"}>
                            <KSpan text={lang.catalog.asset_registry.tracks_and_assets.title_version} />
                            {tooltip("130px", lang.catalog.asset_registry.tracks_and_assets.title_version_tooltip, "")}
                          </div>
                          <Input
                            className={`border mt-2 mb-2`}
                            placeholder={""}
                            value={selectedTrack?.data?.attributes?.subtitle}
                            onChange={(event) => updateTrack("subtitle", event.target.value)}
                          />

                          <div className={"mt-4"}>
                            <KSpan text={lang.catalog.asset_registry.tracks_and_assets.work} />
                          </div>
                          <Input
                            className={`border mt-2 mb-2`}
                            placeholder={""}
                            value={selectedTrack?.data?.attributes?.work}
                            onChange={(event) => updateTrack("work", event.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"m-4 bg-white"}>
                    <div className={"p-4"}>
                      <KTitleSpan
                        fontSize={18}
                        lineHeight="auto"
                        text={lang.catalog.asset_registry.tracks_and_assets.information}
                      />
                      <div className={"flex mt-5"}>
                        <div style={{ width: "50%", paddingRight: 15 }} id="parent-div">
                          {/**
                             * <p className={"text-gray-500"}>
                                ISRC<span style={{ color: "red" }}>&#42;</span>
                              </p>
                              <Input
                                className={`border mt-2 mb-2`}
                                placeholder={""}
                                value={selectedTrack?.data?.attributes["isrc-code"]}
                                onChange={(event) => updateTrack("isrc-code", event.target.value)}
                              />
                             */}

                          <div className={"flex"}>
                            <KSpan text={lang.catalog.asset_registry.tracks_and_assets.audio_language} />
                            <span className="ml-0.5" style={{ color: "red" }}>
                              &#42;
                            </span>
                          </div>
                          <div className={"relative"}>
                            <SearchSelect
                              className={"mt-2 !relative"}
                              placeholder={lang.catalog.asset_registry.tracks_and_assets.audio_language_placeholder}
                              inputTextClass="text-[16px]"
                              options={languageOptions}
                              onTextChange={(event) =>
                                onSearchTextChanged(event, props.options.languageOptions, setLanguageOptions)
                              }
                              value={selectedTrack?.data.relationships["audio-language"]?.data?.id}
                              onChange={(element) => {
                                updateTrackRelationships("audio-language", {
                                  data: { type: "languages", id: element }
                                })
                              }}
                            />
                          </div>

                          <div className={"relative"}>
                            <div className={"flex"}>
                              <div className={"mt-5"}>
                                <KSpan text={lang.catalog.asset_registry.tracks_and_assets.production} />
                              </div>
                              {tooltip(
                                "350px",
                                lang.catalog.asset_registry.tracks_and_assets.production_tooltip,
                                "mt-6"
                              )}
                            </div>
                          </div>
                          <Input
                            className={`border mt-2 mb-2`}
                            placeholder={""}
                            value={selectedTrack?.data.attributes["production-line"]}
                            onChange={(event) => updateTrack("production-line", event.target.value)}
                          />
                        </div>
                        <div style={{ width: "50%" }}>
                          <KSpan text={"ISWC"} />
                          <Input
                            className={`border mt-2 mb-2`}
                            placeholder={""}
                            value={selectedTrack?.data?.attributes["iswc-code"]}
                            onChange={(event) => updateTrack("iswc-code", event.target.value)}
                          />

                          <div className={"mt-3"}>
                            <KSpan text={lang.catalog.asset_registry.tracks_and_assets.publisher} />
                          </div>
                          <Input
                            className={`border mt-2 mb-2`}
                            placeholder={""}
                            value={selectedTrack?.data.attributes["publisher"]}
                            onChange={(event) => updateTrack("publisher", [event.target.value])}
                          />
                        </div>
                      </div>

                      <div>
                        <AudioPlayerRange
                          src={selectedTrack?.data?.attributes["preview-url"]}
                          updateTrackMultiple={updateTrackMultiple}
                          previewStart={selectedTrack?.data?.attributes["preview-start"]}
                        />
                      </div>
                      <div className={"flex mt-4"}>
                        <div style={{ width: "50%", paddingRight: 15 }} id="parent-div">
                          <div className={"mt-5"}>
                            <KSpan text={lang.catalog.asset_registry.tracks_and_assets.usages} />
                          </div>
                          {renderCheckBoxInput("download", lang.catalog.asset_registry.tracks_and_assets.download)}
                          {renderCheckBoxInput(
                            "on_demand_stream",
                            lang.catalog.asset_registry.tracks_and_assets.streaming
                          )}
                          {renderCheckBoxInput("copy_scan", lang.catalog.asset_registry.tracks_and_assets.copy_scan)}
                          {renderCheckBoxInput(
                            "non_interactive_stream",
                            lang.catalog.asset_registry.tracks_and_assets.radio
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"flex"}>
                <div className={"m-4 p-4 bg-white"} style={{ width: "100%" }}>
                  <div className="flex">
                    <KTitleSpan
                      fontSize={18}
                      lineHeight="auto"
                      text={lang.catalog.asset_registry.tracks_and_assets.artists}
                    />
                    <span className="ml-0.5" style={{ color: "red" }}>
                      &#42;
                    </span>
                  </div>
                  <div className={"mt-2"}>
                    <KSpan text={lang.catalog.asset_registry.tracks_and_assets.enter_main_artist_desc} />
                  </div>
                  <div className={"mt-2"}>{artistSelection(true, "artist")}</div>
                  <div className={"relative"}>
                    <div className={"mt-6 flex"}>
                      <KTitleSpan
                        fontSize={18}
                        lineHeight="auto"
                        text={lang.catalog.asset_registry.tracks_and_assets.contributors}
                      />
                      {tooltip("300px", lang.catalog.asset_registry.tracks_and_assets.contributors_tooltip, "mt-1")}
                    </div>
                  </div>

                  <div className={"mt-2"}>
                    <KSpan text={lang.catalog.asset_registry.tracks_and_assets.add_contributor_desc} />
                  </div>
                  <div className={"mt-2"}>
                    {Object.entries(contributors).map(([key, value]) => {
                      return artistSelection(false, key)
                    })}
                  </div>
                  <div className={"mt-2"}>
                    <Button
                      text={lang.catalog.asset_registry.tracks_and_assets.add_contributor_button}
                      onClick={() => {
                        setContributors({ ...contributors, new: [] })
                      }}
                      light
                      buttonType="button"
                    />
                  </div>

                  <div className={"relative"}>
                    <div className={"mt-6 flex"}>
                      <KTitleSpan
                        fontSize={18}
                        lineHeight="auto"
                        text={lang.catalog.asset_registry.tracks_and_assets.track_share_holders}
                      />
                      {tooltip(
                        "300px",
                        lang.catalog.asset_registry.tracks_and_assets.track_share_holders_tooltip,
                        "mt-1"
                      )}
                    </div>
                  </div>

                  <div className={"mt-2 mb-1 grid"}>
                    <KSpan text={lang.catalog.asset_registry.tracks_and_assets.track_share_holders_desc_first} />
                    <KSpan text={lang.catalog.asset_registry.tracks_and_assets.track_share_holders_desc_second} />
                  </div>
                  {renderTrackShareHolders()}

                  {isPublishing && (
                    <div>
                      <div className={"relative"}>
                        <div className={"mt-6 flex"}>
                          <KTitleSpan
                            fontSize={18}
                            lineHeight="auto"
                            text={lang.catalog.asset_registry.tracks_and_assets.publishing_share_holders}
                          />
                          {tooltip(
                            "300px",
                            lang.catalog.asset_registry.tracks_and_assets.track_share_holders_tooltip,
                            "mt-1"
                          )}
                        </div>
                      </div>
                      <div className={"mt-2 mb-1"}>
                        <KSpan text={lang.catalog.asset_registry.tracks_and_assets.edit_publishing_share_holders} />
                      </div>
                      {renderPublishingShareHolders()}
                    </div>
                  )}
                </div>
              </div>
              <div className={"flex"}>
                <div className={"m-4 bg-white p-4"} style={{ width: "100%" }}>
                  <KTitleSpan
                    fontSize={18}
                    lineHeight="auto"
                    text={lang.catalog.asset_registry.tracks_and_assets.contributor_consent_files}
                  />

                  <div className={"mt-3"}>
                    <KSpan text={lang.catalog.asset_registry.tracks_and_assets.contributor_consent_files_desc} />
                  </div>
                  <UploadContributorConsentFileComponent
                    trackRegistryConsentFileList={trackRegistryConsentFileList}
                    setTrackRegistryConsentFileList={setTrackRegistryConsentFileList}
                    isrc={selectedTrack?.data?.attributes["isrc-code"]}
                    upc={releaseForm.product.data.attributes["upc-code"]}
                  />
                </div>
              </div>
              <div className={"flex"}>
                <div className={"m-4 bg-white p-4"} style={{ width: "100%" }}>
                  <KTitleSpan
                    fontSize={18}
                    lineHeight="auto"
                    text={lang.catalog.asset_registry.tracks_and_assets.lyrics}
                  />

                  <textarea
                    className={`border rounded p-3 mt-5 mb-2 w-[100%]`}
                    placeholder={""}
                    rows={8}
                    value={selectedTrack?.data?.attributes?.lyrics}
                    onChange={(event) => updateTrack("lyrics", event.target.value)}
                  />
                  <div>
                    <KButton
                      background="linear-gradient(rgb(30, 117, 223),rgb(31, 149, 183)) rgb(30, 117, 223)"
                      // background="rgb(31, 149, 183)"
                      textColor="white"
                      text={lang.catalog.asset_registry.tracks_and_assets.auto_generate_lyrics_button}
                      onClick={() => {
                        if (selectedTrack?.data?.attributes["preview-url"]) {
                          const attributes = selectedTrack?.data?.attributes
                          openWidget(
                            selectedTrack?.data?.id || "null",
                            attributes["isrc-code"],
                            attributes.title,
                            attributes.artist?.join(", ") || "-",
                            releaseForm.product.data.attributes.name
                          )
                        } else {
                          toast.warning(
                            lang.catalog.asset_registry.tracks_and_assets.success.upload_track_first,
                            warningFilledToastOptions
                          )
                        }
                      }}
                    />
                  </div>

                  <div className={"mt-4"}>
                    <KSpan text={lang.catalog.asset_registry.tracks_and_assets.explicit_content} />
                  </div>
                  <div className={"flex mt-2"}>
                    <input
                      style={{ cursor: "pointer", accentColor: "black" }}
                      type="checkbox"
                      checked={selectedTrack?.data?.attributes["parental-advisory"]}
                      onChange={() =>
                        updateTrack("parental-advisory", !selectedTrack?.data?.attributes["parental-advisory"])
                      }
                    />
                    <div className={"ml-2 mr-8"}>
                      <KSpan text={lang.catalog.asset_registry.tracks_and_assets.parental_advisory} />
                    </div>
                    <input
                      style={{ cursor: "pointer", accentColor: "black" }}
                      type="checkbox"
                      checked={selectedTrack?.data?.attributes["clean"]}
                      onChange={() => updateTrack("clean", !selectedTrack?.data?.attributes["clean"])}
                    />
                    <div className={"ml-2"}>
                      <KSpan text={lang.catalog.asset_registry.tracks_and_assets.clean} />
                    </div>
                    <div className={"relative"}>
                      {tooltip("360px", lang.catalog.asset_registry.tracks_and_assets.clean_tooltip, "mt-1")}
                    </div>
                  </div>
                </div>
              </div>

              <div className={"flex"}>
                <div className={"m-4 bg-white p-4"} style={{ width: "100%" }}>
                  <KTitleSpan
                    fontSize={18}
                    lineHeight="auto"
                    text={lang.catalog.asset_registry.tracks_and_assets.videos}
                  />

                  <div className={"mb-4 mt-2"}>
                    <KSpan text={lang.catalog.asset_registry.tracks_and_assets.auto_generate_videos_button} />
                  </div>

                  <div className="flex gap-2">
                    <KButton
                      background="linear-gradient(270deg, #e1387c 0%, #02bad8 100%)"
                      textColor="white"
                      disabled={loading}
                      text={lang.catalog.asset_registry.tracks_and_assets.classic}
                      onClick={() => onClickAutoGenerateVideos(true)}
                    />
                    <KButton
                      background="linear-gradient(270deg, #e1387c 0%, #02bad8 100%)"
                      textColor="white"
                      disabled={loading}
                      text={lang.catalog.asset_registry.tracks_and_assets.spotify_canvas_apple}
                      onClick={() => onClickAutoGenerateVideos(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={"mb-20"} />
          </div>
          <div className={"justify-center flex absolute bg-white border-t pb-2 pt-2"} style={{ width: "60%" }}>
            <KButton
              width="250px"
              text={lang.catalog.asset_registry.tracks_and_assets.update_button}
              onClick={() => saveTrack()}
              background="black"
              textColor="white"
              disabled={loading}
            />
          </div>
        </div>
      )}
      <TrackShareHolderModal
        selectedTrackShareHolder={selectedTrackShareHolder}
        setSelectedTrackShareHolder={setSelectedTrackShareHolder}
        handleConfirm={() => confirmTrackShareHolder()}
        trackShareHolderRoleOptions={trackShareHolderRoleOptions}
      />
      <PublishingShareHolderModal
        selectedPublishingShareHolder={selectedPublishingShareHolder}
        setSelectedPublishingShareHolder={setSelectedPublishingShareHolder}
        handleConfirm={() => confirmPublishingShareHolder()}
      />
      {renderRotorVideosClassic()}
      <div ref={divRef} style={{ visibility: "hidden" }}>
        {renderRotorVideosSpotify()}
      </div>
    </div>
  )
}

export default TrackDetail
