import "../analysis/styles/Analysis.css"
import { useNavigate } from "react-router-dom"
import React, { FC, useEffect, useState } from "react"
import AnalysisChart from "../analysis/AnalysisChart"
import AnalysisTopSongs from "../analysis/top-songs/AnalysisTopSongs"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { fetchAllSummaries, fetchFilterOptions, fetchTotals } from "../../../actions/analysisActions"
import { AnalysisSummary, AnalysisTotal } from "../../../types"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { KSpan, KTitleSpan } from "kahuna-base-react-components"
import { PERMISSIONS } from "../../../constants"
import { lang } from "../../../constants/languages"

const Overview = ({
  allSummaries,
  fetchAllSummaries,
  totals,
  fetchTotals,
  filterOptions,
  fetchFilterOptions,
  newReleasePermission
}: {
  allSummaries: AnalysisSummary[]
  fetchAllSummaries: (
    type: string,
    value: string | number,
    affiliate: boolean,
    startDate?: string,
    endDate?: string
  ) => Promise<void>
  totals: AnalysisTotal[]
  fetchTotals: () => Promise<void>
  filterOptions: { [key: string]: KSelectOption[] }
  fetchFilterOptions: () => Promise<void>
  newReleasePermission: boolean
}) => {
  const [loading, setLoading] = useState(true)

  const monthRange = 12
  const defaultStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - (monthRange - 1))
  const defaultEndDate = new Date()

  const navigate = useNavigate()

  const getTotalsAndAllSummaries = async () => {
    setLoading(true)
    const startDateYear = defaultStartDate.getFullYear()
    const startDateMonth = String(defaultStartDate.getMonth() + 1).padStart(2, "0")
    const endDateYear = defaultEndDate.getFullYear()
    const endDateMonth = String(defaultEndDate.getMonth() + 1).padStart(2, "0")
    const startDateParam = `${startDateYear}${startDateMonth}`
    const endDateParam = `${endDateYear}${endDateMonth}`
    try {
      await fetchTotals()
      await fetchAllSummaries("platform", "all_platforms", false, startDateParam, endDateParam)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!filterOptions && fetchFilterOptions) {
      fetchFilterOptions().then(() => {})
    }
    getTotalsAndAllSummaries().then(() => {})
  }, [])

  const renderFirstQuickLink = () => {
    return newReleasePermission ? (
      <div className="quick-access-div" onClick={() => navigate(`/catalog/new-release`)}>
        <img src="/navigation_icons/music.svg" className="quick-access-img" />
        <div className="mt-5">
          <KSpan text={lang.overview.new_release_header} color="#000" fontSize={16} fontWeight={500} />
        </div>
        <div className="mt-3">
          <KSpan text={lang.overview.new_release_desc} />
        </div>
      </div>
    ) : (
      <div className="quick-access-div" onClick={() => navigate("/account/profile")}>
        <img src="/navigation_icons/profile_dropdownmenu_icons/user-filled.svg" className="quick-access-img" />
        <div className="mt-5">
          <KSpan text={lang.overview.profile_header || "Profile"} color="#000" fontSize={16} fontWeight={500} />
        </div>
        <div className="mt-3">
          <KSpan
            text={lang.overview.profile_desc || "Let's go to the profile page and make sure everything is up to date."}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      {!loading && (
        <div className="flex flex-col justify-start gap-4">
          <div className="mt-6 gap-4  rounded-[10px] pb-6">
            {
              <AnalysisChart
                initialData={allSummaries}
                monthRange={monthRange}
                defaultStartDate={defaultStartDate}
                defaultEndDate={defaultEndDate}
              />
            }
          </div>
        </div>
      )}
      <div className="mt-3">
        <div className="pl-4">
          <KTitleSpan text={lang.overview.title} fontSize={32} />
        </div>
        <div
          className="flex mt-4 p-2"
          style={{
            width: "100%",
            borderRadius: 10,
            border: "1px solid var(--stroke-soft-200, #F3F3F3)",
            background: "var(--bg-white-0, #FFF)",
            boxShadow: "0px 2px 4px 0px rgba(27, 28, 29, 0.04)"
          }}
        >
          {renderFirstQuickLink()}
          <div className="mt-2 mb-2" style={{ borderRight: "1px solid #f7f7f7" }} />
          <div className="quick-access-div" onClick={() => navigate("/analytics")}>
            <div className="flex justify-between items-center">
              <img src="/navigation_icons/analytics.svg" className="quick-access-img" />
              <div className="flex gap-1">
                <img src="/navigation_icons/new-badge.svg" />
                <KSpan text="New" fontSize={12} lineHeight="16px" fontWeight={500} color="#FF9558" />
              </div>
            </div>
            <div className="mt-5">
              <KSpan text={lang.overview.analytics_header} color="#000" fontSize={16} fontWeight={500} />
            </div>
            <div className="mt-3">
              <KSpan text={lang.overview.analytics_desc} />
            </div>
          </div>
          <div className="mt-2 mb-2" style={{ borderRight: "1px solid #f7f7f7" }} />
          <div className="quick-access-div" onClick={() => navigate("/artist-space")}>
            <div className="flex justify-between items-center">
              <img src="/navigation_icons/earth.svg" className="quick-access-img" />
              <div className="flex gap-1">
                <img src="/navigation_icons/new-badge.svg" />
                <KSpan text="New" fontSize={12} lineHeight="16px" fontWeight={500} color="#FF9558" />
              </div>
            </div>
            <div className="mt-5">
              <KSpan text={lang.overview.artist_space_header} color="#000" fontSize={16} fontWeight={500} />
            </div>
            <div className="mt-3">
              <KSpan text={lang.overview.artist_space_desc} />
            </div>
          </div>
        </div>
      </div>
      <AnalysisTopSongs />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    totals: state.analysis.totals,
    currency: state.auth.user.preferredCurrency,
    user: state.auth.user,
    selectedDate: state.analysis.selectedDate,
    selectedFilter: state.analysis.selectedFilter,
    summaries: state.analysis.summaries,
    filterOptions: state.analysis.filterOptions,
    allSummaries: state.analysis.allSummaries,
    newReleasePermission: state.auth.user.permissionUser?.some((item) => {
      return item.permission && item.permission.id === PERMISSIONS.NewRelease
    })
  }
}

export default connect(mapStateToProps, { fetchAllSummaries, fetchTotals, fetchFilterOptions })(Overview)
