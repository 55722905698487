import React, { FC, useEffect, useState } from "react"
import { LCReleaseForm, LCReleaseFormData } from "../../../../../types"
import { releases } from "../../../../../apis"
import { useNavigate } from "react-router-dom"
import Accordion from "../../../../ui/Accordion"
import { KButton, KInput, KSpan } from "kahuna-base-react-components"
import { formatDate2 } from "../../../../../utility"
import AudioPlayer from "../../track-detail/AudioPlayer"
import { AssetRegistryPages } from "../../../../../actions/types"
import Loading from "../../../../ui/Loading"
import { successFilledToastOptions } from "../../../../../constants"
import { toast } from "react-toastify"
//@ts-ignore
import AlertIcon from "../../../../../assets/release-registry-icons/alert-triangle.svg"
import {
  buildBMVTrackList,
  buildMesamMsgTrackList,
  buildState51TrackList
} from "../../../../../utility/newReleaseHelpler"
import { lang } from "../../../../../constants/languages"
interface SummaryProps {
  releaseForm: LCReleaseForm
  setReleaseForm: (releaseForm: LCReleaseForm) => void
  options: any
  setSelectedTab: (value: number) => void
  fetchProduct: (productId: string) => void
  setErrorText: (errorText?: string) => void
  isPublishing: boolean
  summaryPublishingText: string
  bmwCustomIdKeyword: string
}

const notAllowedProductRelationships = ["company", "default-offer", "distributor", "offers", "performers", "tracks"]

const Summary: FC<SummaryProps> = (props) => {
  const { releaseForm, isPublishing, summaryPublishingText, bmwCustomIdKeyword } = props

  const navigate = useNavigate()
  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])

  const [missingGeneralFields, setMissingGeneralFields] = useState<boolean>(false)
  const [missingTrackFields, setMissingTrackFields] = useState<boolean>(false)
  const [isRefreshed, setIsRefreshed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkedBoxes, setCheckedBoxes] = useState<{
    first: boolean
    second: boolean
    third: boolean
    fourth: boolean
  }>({
    first: false,
    second: false,
    third: false,
    fourth: false
  })

  const refreshProduct = () => {
    if (releaseForm.product.data.id) {
      props.fetchProduct(releaseForm.product.data.id)
      setIsRefreshed(true)
    }
  }

  useEffect(() => {
    if (checkedBoxes.first && checkedBoxes.second && checkedBoxes.third && checkedBoxes.fourth) {
      refreshProduct()
    }
  }, [checkedBoxes])

  const submitForm = () => {
    const productRelationships = {}
    Object.entries(releaseForm.product.data.relationships).forEach(([key, value]) => {
      if (value["data"] && !notAllowedProductRelationships.includes(key)) {
        productRelationships[key] = { data: value["data"] }
      }
    })
    const productAttributes = {
      status: "reviewing"
    }

    const filteredReleaseForm = {
      data: {
        id: releaseForm.product.data.id,
        attributes: productAttributes,
        relationships: productRelationships,
        type: "products"
      },
      bmv_track_list: buildBMVTrackList(releaseForm, props.options, bmwCustomIdKeyword),
      state_51_form: buildState51TrackList(releaseForm, props.options),
      mesam_msg_track_list: buildMesamMsgTrackList(releaseForm, props.options),
      artists: releaseForm.product.data.attributes.artist?.join(", ", "") || ""
    }
    try {
      setLoading(true)
      releases(`/release/label_camp_product_update/`, "post", { ...filteredReleaseForm })
        .then((resp) => {
          setLoading(false)
          if (resp.data.success) {
            if (isPublishing) {
              toast.success(summaryPublishingText, successFilledToastOptions)
            }

            navigate("/catalog")
          } else {
            props.setErrorText(resp.data.result)
          }
        })
        .catch((err) => {
          props.setErrorText(err)
          setLoading(false)
          console.error(err)
        })
    } catch (e) {
      setLoading(false)
      console.error("Error saving product")
      props.setErrorText(e)
    }
  }

  const getAttribute = (attribute: string) => {
    if (Array.isArray(releaseForm.product.data.attributes[attribute])) {
      // this is added just for artist option for now. it returns an array
      return releaseForm.product.data.attributes[attribute].join(",")
    }
    return releaseForm.product.data.attributes[attribute]
  }

  const formatDuration = (value: number) => {
    const min = Math.floor(value / 60)
    const sec = value % 60
    if (sec < 10) {
      return `${min}:0${sec}`
    }
    return `${min}:${sec}`
  }

  const renderFields = (propName: string, text: string, value: string, required: boolean) => {
    if (!missingGeneralFields && required && !value) {
      setMissingGeneralFields(true)
    }

    return (
      <div className="flex flex-row">
        <div
          className="flex flex-row gap-2 items-center px-1 rounded-[4px]"
          style={{
            backgroundColor: !value && required ? "rgba(255, 0, 0, 0.2)" : "transparent"
          }}
        >
          {propName !== "copyright-line" && propName !== "production-line" && (
            <div className="flex flex-row gap-2">
              <KSpan text={text} color={!value && required ? "#f00" : "#333333"} />
              <KSpan
                text={
                  value
                    ? value
                    : propName === "cover-url" || propName === "booklet"
                    ? lang.catalog.asset_registry.summary.no_file
                    : required
                    ? lang.catalog.asset_registry.summary.missing_item
                    : "-"
                }
                color={!value && required ? "#ff6666" : "#737373"}
                fontStyle={value ? "normal" : "italic"}
              />
            </div>
          )}
          {(propName === "copyright-line" || propName === "production-line") && (
            <div className="flex flex-row gap-2 items-center">
              <div
                className="flex flex-row gap-1 items-center"
                style={{
                  color: value ? "#333333" : "#f00"
                }}
              >
                {propName === "copyright-line" ? (
                  <React.Fragment> &#9400;</React.Fragment>
                ) : (
                  <React.Fragment> &#x24C5;</React.Fragment>
                )}
                <KSpan text={text} color={!value && required ? "#f00" : "#333333"} />
              </div>

              <KSpan
                text={value ? value : lang.catalog.asset_registry.summary.missing_item}
                color={!value && required ? "#ff6666" : "#737373"}
                fontStyle={value ? "normal" : "italic"}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderAssets = (track: LCReleaseFormData) => {
    const opacity16Bit = track.data.attributes?.audio16bits ? "1" : "0.2"
    const opacityis24Bit = track.data.attributes?.audio24bits ? "1" : "0.2"
    const opacityisDolby = track.data.attributes?.["audio-dolby-atmos"] ? "1" : "0.2"

    const missingItem = opacity16Bit === "0.2" && opacityis24Bit === "0.2" && opacityisDolby === "0.2"

    if (missingItem && !missingTrackFields) {
      setMissingTrackFields(true)
    }

    if (missingItem) {
      return <KSpan text={lang.catalog.asset_registry.summary.missing_assets} color="#ff6666" fontStyle="italic" />
    }

    return (
      <div>
        <div className="flex items-center gap-1">
          <div style={{ background: `rgba(33, 164, 148, ${opacity16Bit})`, borderRadius: 5 }} className="pl-1 pr-1">
            <KSpan text="16-Bit" color="white" />
          </div>
          <div style={{ background: `rgba(187, 204, 19, ${opacityis24Bit})`, borderRadius: 5 }} className="pl-1 pr-1">
            <KSpan text="24-Bit" color="white" />
          </div>
          <div style={{ background: `rgba(48, 39, 44, ${opacityisDolby})`, borderRadius: 5 }} className="p-1">
            <img width={40} height={28} src={"/catalog_icons/dolby.svg"}></img>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {loading && <Loading />}
      <div className={"flex"}>
        <div className={"bg-white flex m-4"} style={{ width: "100%" }}>
          <div style={{ width: "25%" }} className={"p-5"}>
            {releaseForm.product.data.attributes["cover-url"] && (
              <img
                src={releaseForm.product.data.attributes["cover-url"]}
                alt="Uploaded"
                style={{ width: "240px", borderRadius: 5 }}
              />
            )}
          </div>
          <div style={{ width: "75%" }} className={"p-5"}>
            <div>
              <Accordion
                title={
                  <div className="flex justify-between" style={{ width: "100%" }}>
                    <div className={"relative"}>
                      <div className="flex">
                        <KSpan
                          text={lang.catalog.asset_registry.navigation_steps.general_information}
                          fontWeight={500}
                          fontSize={16}
                          color="#111"
                        />
                      </div>
                      <div>
                        <KSpan
                          text={`${
                            releaseForm.product.data.attributes["name"]
                              ? releaseForm.product.data.attributes["name"]
                              : lang.catalog.asset_registry.summary.untitled_album
                          } - ${
                            releaseForm.product.data.attributes["artist"]?.length > 0
                              ? releaseForm.product.data.attributes["artist"]
                              : lang.catalog.asset_registry.summary.no_artist
                          }`}
                        />
                      </div>
                    </div>
                    <div className="flex items-center mr-4">
                      <div className="flex">
                        {missingGeneralFields && (
                          <div className="mr-4" style={{ minWidth: "fit-content" }}>
                            <KSpan
                              color="red"
                              text={lang.catalog.asset_registry.summary.missing_item}
                              fontStyle="italic"
                            />
                          </div>
                        )}
                        <KButton
                          background="transparent"
                          height="30px"
                          text={lang.catalog.asset_registry.summary.edit_button}
                          onClick={() => props.setSelectedTab(AssetRegistryPages.GENERAL_INFORMATION)}
                        />
                      </div>
                    </div>
                  </div>
                }
                content={
                  <div className="p-7 flex flex-row">
                    <div className="flex flex-col grow gap-6">
                      <div className="flex flex-col gap-2">
                        {renderFields(
                          "label",
                          `${lang.catalog.asset_registry.general_information.label}:`,
                          props.options.labelOptions.find(
                            (label) => label.value === releaseForm.product.data?.relationships.label?.data?.id
                          )?.label,
                          true
                        )}
                        {renderFields(
                          "subgenre",
                          `${lang.catalog.asset_registry.general_information.main_genre}:`,
                          props.options.distributorProductSubGenreOptions.find(
                            (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
                          )?.label,
                          true
                        )}
                        {renderFields(
                          "subgenre2",
                          `${lang.catalog.asset_registry.general_information.secondary_genre}:`,
                          props.options.distributorProductSubGenreOptions.find(
                            (subgenre2) =>
                              subgenre2.value === releaseForm.offer.data.relationships["subgenre2"]?.data?.id
                          )?.label,
                          false
                        )}
                        {renderFields("upc-code", "UPC:", releaseForm.product.data.attributes["upc-code"], true)}
                        {renderFields(
                          "product-type",
                          `${lang.catalog.asset_registry.general_information.product_type}:`,
                          props.options.productTypeOptions.find(
                            (genre) => genre.value === releaseForm.product.data.relationships["product-type"]?.data?.id
                          )?.label,
                          true
                        )}
                        {renderFields(
                          "release-date",
                          `${lang.catalog.asset_registry.general_information.release_date}:`,
                          formatDate2(releaseForm.offer.data.attributes["release-date"]),
                          true
                        )}
                        {renderFields(
                          "original-release-date",
                          `${lang.catalog.asset_registry.summary.original_release_date}:`,
                          formatDate2(getAttribute("original-release-date")),
                          false
                        )}
                        {renderFields(
                          "preorder-date",
                          `${lang.catalog.asset_registry.summary.preorder_date}:`,
                          formatDate2(releaseForm.offer.data.attributes["preorder-date"]),
                          false
                        )}
                        {renderFields(
                          "language",
                          `${lang.catalog.asset_registry.general_information.metadata_language}:`,
                          props.options.languageOptions.find(
                            (language) => language.value === releaseForm.product.data?.relationships.language?.data?.id
                          )?.label,
                          true
                        )}
                      </div>
                      <div className="grow flex flex-col gap-2">
                        <div className="pl-1">
                          <KSpan text={lang.catalog.asset_registry.summary.artwork} color="#333333" />
                        </div>

                        {renderFields(
                          "cover-url",
                          `${lang.catalog.asset_registry.general_information.cover_art}:`,
                          releaseForm.product.data.attributes["cover-url"]
                            ? lang.catalog.asset_registry.summary.cover_uploaded
                            : releaseForm.product.data.attributes["cover-url"],
                          true
                        )}

                        {renderFields(
                          "booklet",
                          `${lang.catalog.asset_registry.general_information.booklet}:`,
                          releaseForm.product.data.attributes["booklet"],
                          false
                        )}
                      </div>
                      <div className="grow flex flex-col gap-2">
                        <div className="pl-1">
                          <KSpan text={lang.catalog.asset_registry.general_information.artists} color="#333333" />
                        </div>
                        {renderFields(
                          "artist",
                          `${lang.catalog.asset_registry.tracks_and_assets.main_artist}:`,
                          releaseForm.product.data.attributes["artist"]?.join(","),
                          true
                        )}
                      </div>
                    </div>
                    <div className="grow flex flex-col gap-6">
                      <div className=" flex flex-col gap-2">
                        {renderFields(
                          "production-year",
                          `${lang.catalog.asset_registry.general_information.production_year}:`,
                          getAttribute("production-year"),
                          false
                        )}
                        {renderFields(
                          "copyright-line",
                          `${lang.catalog.asset_registry.general_information.copyright_line}:`,
                          releaseForm.product.data.attributes["copyright-line"],
                          true
                        )}
                        {renderFields(
                          "production-line",
                          `${lang.catalog.asset_registry.general_information.production_line}:`,
                          releaseForm.product.data.attributes["production-line"],
                          true
                        )}
                        {renderFields(
                          "label-internal-reference",
                          `${lang.catalog.asset_registry.summary.catalog_number}:`,
                          getAttribute("label-internal-reference"),
                          false
                        )}
                      </div>
                      <div className=" flex flex-col gap-2 pt-3">
                        {renderFields(
                          "price-code",
                          `${lang.catalog.asset_registry.summary.album_price}:`,
                          props.options.distributorPriceCodeOptions
                            .find(
                              (price) => price.value === releaseForm.offer.data?.relationships["price-code"]?.data?.id
                            )
                            ?.label.split("-")[1],
                          true
                        )}
                        {renderFields(
                          "track-price-code",
                          `${lang.catalog.asset_registry.summary.track_price}:`,
                          props.options.distributorTrackPriceOptions
                            .find(
                              (price) =>
                                price.value === releaseForm.offer.data?.relationships["track-price-code"]?.data?.id
                            )
                            ?.label.split("-")[1],
                          true
                        )}
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            <div className={"mt-4"}>
              <Accordion
                title={
                  <div className="flex justify-between" style={{ width: "100%" }}>
                    <div className={"relative"}>
                      <div>
                        <KSpan
                          text={lang.catalog.asset_registry.navigation_steps.tracks_and_assets}
                          color="#111"
                          fontWeight={500}
                          fontSize={16}
                        />
                      </div>
                      <div>
                        <KSpan text={`Volume 1 - ${releaseForm.tracks.length} tracks`} />
                      </div>
                    </div>
                    <div className="flex items-center mr-4">
                      <div className="flex">
                        {missingTrackFields && (
                          <div className="mr-4" style={{ minWidth: "fit-content" }}>
                            <KSpan
                              color="red"
                              text={lang.catalog.asset_registry.summary.missing_item}
                              fontStyle="italic"
                            />
                          </div>
                        )}
                        <KButton
                          background="transparent"
                          height="30px"
                          text={lang.catalog.asset_registry.summary.edit_button}
                          onClick={() => props.setSelectedTab(AssetRegistryPages.TRACKS_AND_ASSETS)}
                        />
                      </div>
                    </div>
                  </div>
                }
                content={
                  <div className="px-7 py-4 flex flex-col gap-4">
                    <div className="flex flex-row gap-2 pb-2">
                      <div className="w-[30%] flex items-start pl-7 ">
                        <KSpan text={lang.catalog.asset_registry.summary.title} fontWeight={500} color="#000" />
                      </div>
                      <div className="w-[20%] flex items-start">
                        <KSpan text="ISRC" fontWeight={500} color="#000" />
                      </div>
                      <div className="w-[20%] flex items-start">
                        <KSpan
                          text={lang.catalog.asset_registry.summary.audio_language}
                          fontWeight={500}
                          color="#000"
                        />
                      </div>
                      <div className="w-[20%] flex items-start">
                        <KSpan text={lang.catalog.asset_registry.summary.audio_format} fontWeight={500} color="#000" />
                      </div>
                      <div className="w-[10%] flex items-start pl-1">
                        <KSpan text={lang.catalog.asset_registry.summary.duration} fontWeight={500} color="#000" />
                      </div>
                    </div>
                    {releaseForm.tracks.map((track, index: number) => {
                      if (
                        !missingTrackFields &&
                        (!track.data.attributes.title ||
                          !track.data?.attributes["artist"] ||
                          !track.data?.attributes["isrc-code"] ||
                          !track?.data.relationships["audio-language"]?.data?.id)
                      ) {
                        setMissingTrackFields(true)
                      }
                      const audioLanguage = props.options.languageOptions.find(
                        (language) => language.value === track?.data.relationships["audio-language"]?.data?.id
                      )?.label

                      return (
                        <div key={`track-${index}`} className="flex flex-row py-1 h-12 items-center gap-2">
                          <div className="flex flex-row gap-3 justify-start items-center cursor-pointer w-[30%]">
                            <AudioPlayer
                              src={track.data.attributes["preview-url"]}
                              playingAudioRefs={playingAudioRefs}
                              setPlayingAudioRefs={setPlayingAudioRefs}
                            />
                            <div className="flex flex-col">
                              <KSpan
                                text={track.data.attributes.title || lang.catalog.asset_registry.summary.missing_title}
                                color={track.data.attributes.title ? "#111" : "#ff6666"}
                                fontWeight={500}
                                fontStyle={track.data.attributes.title ? "normal" : "italic"}
                              />

                              {track.data?.attributes["artist"]?.length > 0 ? (
                                <KSpan text={track.data?.attributes["artist"]?.join(", ")} fontSize={12} />
                              ) : (
                                <KSpan
                                  text={lang.catalog.asset_registry.summary.missing_artist}
                                  color="#ff6666"
                                  fontStyle="italic"
                                />
                              )}
                            </div>
                          </div>
                          <div className="w-[20%] flex items-center justify-start">
                            <KSpan
                              text={track.data?.attributes["isrc-code"] || "missing ISRC"}
                              color={track.data?.attributes["isrc-code"] ? "#111" : "#ff6666"}
                              fontWeight={500}
                              fontStyle={track.data.attributes.title ? "normal" : "italic"}
                            />
                          </div>
                          <div className="w-[20%] flex items-center justify-start">
                            <KSpan
                              text={audioLanguage || lang.catalog.asset_registry.summary.missing_language}
                              color={audioLanguage ? "#111" : "#ff6666"}
                              fontWeight={500}
                              fontStyle={audioLanguage ? "normal" : "italic"}
                            />
                          </div>
                          <div className="w-[20%] flex items-center justify-start">{renderAssets(track)}</div>
                          <div className="w-[10%] flex items-center justify-start pl-1">
                            <KSpan text={formatDuration(track.data.attributes.length)} color="#111" fontWeight={500} />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                }
              />
            </div>
            <div className={"mt-4 border border-gray-200 border-solid rounded-lg pl-[13px] flex justify-between"}>
              <div className={"relative"}>
                <div>
                  <KSpan
                    text={lang.catalog.asset_registry.navigation_steps.territory_and_platform_rights}
                    color="#111"
                    fontSize={16}
                    fontWeight={500}
                  />
                </div>
                <div>
                  <KSpan
                    text={
                      releaseForm.product.data.attributes["label-rights-message"]
                        ? releaseForm.product.data.attributes["label-rights-message"]
                        : lang.catalog.asset_registry.summary.no_comment
                    }
                  />
                </div>
              </div>
              <div className="flex items-center mr-12">
                <div className="flex">
                  {!releaseForm.product.data.attributes["are-rights-confirmed"] && (
                    <div className="mr-4" style={{ minWidth: "fit-content" }}>
                      <KSpan color="red" text={lang.catalog.asset_registry.summary.missing_item} fontStyle="italic" />
                    </div>
                  )}
                  <KButton
                    background="transparent"
                    height="30px"
                    text={lang.catalog.asset_registry.summary.edit_button}
                    onClick={() => props.setSelectedTab(AssetRegistryPages.TERRITORY_AND_PLATFORM_RIGHTS)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div className="flex flex-col gap-3 pl-1">
                <div className="flex flex-row gap-2">
                  <div className="pt-0.5">
                    <KInput
                      value={"checkTerms"}
                      onChange={() => {
                        setCheckedBoxes({ ...checkedBoxes, first: !checkedBoxes.first })
                      }}
                      type={"checkbox"}
                      checked={checkedBoxes.first}
                      shadowDisabled={true}
                      width={16}
                      height={16}
                      accentColor={"black"}
                      padding="0px"
                      borderRadius={10}
                    />
                  </div>
                  <KSpan
                    text={lang.catalog.asset_registry.summary.correct_labeling_explicit_content}
                    lineHeight="20px"
                  />
                </div>
                <div className="flex flex-row gap-2 items-start">
                  <KInput
                    value={"checkTerms"}
                    onChange={() => {
                      setCheckedBoxes({ ...checkedBoxes, second: !checkedBoxes.second })
                    }}
                    type={"checkbox"}
                    checked={checkedBoxes.second}
                    shadowDisabled={true}
                    width={16}
                    height={16}
                    accentColor={"black"}
                    padding="0px"
                    borderRadius={10}
                  />
                  <KSpan text={lang.catalog.asset_registry.summary.authorised_to_distribute} lineHeight="20px" />
                </div>
                <div className="flex flex-row gap-2 items-start">
                  <div className="pt-0.5">
                    <KInput
                      value={"checkTerms"}
                      onChange={() => {
                        setCheckedBoxes({ ...checkedBoxes, third: !checkedBoxes.third })
                      }}
                      type={"checkbox"}
                      checked={checkedBoxes.third}
                      shadowDisabled={true}
                      width={16}
                      height={16}
                      accentColor={"black"}
                      padding="0px"
                      borderRadius={10}
                    />
                  </div>
                  <KSpan text={lang.catalog.asset_registry.summary.no_plagiarism} lineHeight="20px" />
                </div>
                <div className="flex flex-row gap-2 items-start">
                  <div className="pt-0.5">
                    <KInput
                      value={"checkTerms"}
                      onChange={() => {
                        setCheckedBoxes({ ...checkedBoxes, fourth: !checkedBoxes.fourth })
                      }}
                      type={"checkbox"}
                      checked={checkedBoxes.fourth}
                      shadowDisabled={true}
                      width={16}
                      height={16}
                      accentColor={"black"}
                      padding="0px"
                      borderRadius={10}
                    />
                  </div>
                  <KSpan text={lang.catalog.asset_registry.summary.no_mislabeling} lineHeight="20px" />
                </div>
              </div>
              <div className="mt-4">
                <div className="px-[14px] pt-[14px] pb-4 flex flex-row gap-3 bg-[#FFF7EA] rounded-[10px]">
                  <div className="shrink-0">
                    <img src={AlertIcon} style={{ width: 20 }} />
                  </div>
                  <div className="opacity-80 inline-flex">
                    <KSpan text={lang.catalog.asset_registry.summary.warning_text} color="#4D360E" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"justify-end flex"} style={{ marginBottom: 12, marginRight: 40, marginLeft: 40, gap: 12 }}>
        <KButton
          width="400px"
          text={lang.common.submit}
          onClick={() => submitForm()}
          background="black"
          disabled={
            missingGeneralFields ||
            missingTrackFields ||
            !releaseForm.product.data.attributes["are-rights-confirmed"] ||
            !isRefreshed ||
            !checkedBoxes.first ||
            !checkedBoxes.second ||
            !checkedBoxes.third ||
            !checkedBoxes.fourth
          }
          textColor="white"
        />
      </div>
    </div>
  )
}

export default Summary
