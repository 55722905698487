import React, { FC, useEffect, useState } from "react"
import { analysisFilterOptionsKDropdown } from "../../../constants"
import { AnalysisChartProps, AnalysisSummary } from "../../../types"
import {
  addCommasToNumber,
  calculateChartPercentage,
  calculateOffsetMonth,
  convertToMonthYear,
  generateMonthRange,
  getCurrency,
  getSummaries,
  mapListToCamelCase,
  subOptionsSetter
} from "../../../utility"
import { fetchSummaries, selectFilter, fetchFilterOptions, fetchAllSummaries } from "../../../actions/analysisActions"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { Bar } from "react-chartjs-2"
import { Chart as ChartJS, registerables, BarElement } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { KDropdown, KSelectDate, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { lang } from "../../../constants/languages"

ChartJS.register(...registerables, ChartDataLabels, BarElement)

const dateOptions: KSelectOption[] = [
  { label: "1 Year", value: 12 },
  { label: "2 Years", value: 24 },
  { label: "3 Years", value: 36 },
  { label: "5 Years", value: 60 }
]

const AnalysisChart: FC<AnalysisChartProps> = (props) => {
  const { currency, fetchFilterOptions, filterOptions, fetchAllSummaries, initialData } = props

  interface myData {
    date: string
    totalNet: number
  }

  const [data, setData] = useState<AnalysisSummary[]>(initialData)

  const [chartData, setChartData] = useState<AnalysisSummary[] | myData[]>([])

  const [total, setTotal] = useState<number>(0)

  const [selectedFilterOption, setSelectedFilterOption] = useState<KSelectOption>(analysisFilterOptionsKDropdown[1])

  const [subOptions, setSubOptions] = useState<KSelectOption[]>([])

  const [selectedSubOption, setSelectedSubOption] = useState<KSelectOption>(subOptions[0])

  const [loading, setLoading] = useState<boolean>(false)

  const [hoveredIndex, setHoveredIndex] = useState<number>(-1)

  const [increased, setIncreased] = useState<boolean | null>(null)

  const [percentChange, setPercentChange] = useState<number | undefined>(undefined)

  const [activeBarData, setActiveBarData] = useState<{ totalNet: number; date: string }>({ totalNet: 0, date: "" })

  const [maxValue, setMaxValue] = useState<number>(1) //maxValue in allSummaries

  const [chosenMonth, setChosenMonth] = useState<string | undefined>(undefined)

  const [dateLabels, setDateLabels] = useState<{ start: string; end: string }>({ start: "", end: "" })

  const [dateValue, setDateValue] = useState<Date | undefined>(props.defaultStartDate)

  useEffect(() => {
    const startDate = props.defaultStartDate
    const startDateYear = startDate.getFullYear()
    const startDateMonth = String(startDate.getMonth() + 1).padStart(2, "0")
    const startDateLabel = chosenMonth
      ? convertToMonthYear(chosenMonth.replace("-", ""))
      : convertToMonthYear(`${startDateYear}${startDateMonth}`)
    const endDate = props.defaultEndDate

    const endDateYear = endDate.getFullYear()
    const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0")
    const endDateLabel = chosenMonth
      ? convertToMonthYear(calculateOffsetMonth(chosenMonth, (props.monthRange - 1)).replace("-", ""))
      : convertToMonthYear(`${endDateYear}${endDateMonth}`)

    setDateLabels({ start: startDateLabel, end: endDateLabel })
  }, [chosenMonth])

  const getFilteredMonths = (dateList: string[]): string[] => {
    // Convert the date strings to arrays of [year, month]
    if (dateList.length === 0) {
      return []
    }
    const dates = dateList.map((date) => {
      const year = parseInt(date.slice(0, 4), 10)
      const month = parseInt(date.slice(4, 6), 10)
      return { year, month }
    })

    // Find the min and max dates
    const minDate = dates.reduce((min, current) => {
      if (current.year < min.year || (current.year === min.year && current.month < min.month)) {
        return current
      }
      return min
    })

    const maxDate = dates.reduce((max, current) => {
      if (current.year > max.year || (current.year === max.year && current.month > max.month)) {
        return current
      }
      return max
    })

    // Generate the list of months from minDate to maxDate
    const months: string[] = []
    let currentYear = minDate.year
    let currentMonth = minDate.month

    while (currentYear < maxDate.year || (currentYear === maxDate.year && currentMonth <= maxDate.month)) {
      // Add the current month in YYYYMM format
      const monthString = `${currentYear}${currentMonth.toString().padStart(2, "0")}`
      months.push(monthString)

      // Move to the next month
      currentMonth++
      if (currentMonth > 12) {
        currentMonth = 1
        currentYear++
      }
    }

    return months
  }

  useEffect(() => {
    if (hoveredIndex > 0) {
      const currentMonth = chartData[hoveredIndex].totalNet
      const previousMonth = chartData[hoveredIndex - 1].totalNet
      if (currentMonth && previousMonth) {
        const calculatedPercentChange = Number(
          (((Number(currentMonth) - Number(previousMonth)) / Number(previousMonth)) * 100).toFixed(0)
        )
        setPercentChange(calculatedPercentChange)
      }
      if (chartData[hoveredIndex].totalNet > chartData[hoveredIndex - 1].totalNet) {
        setIncreased(true)
      } else {
        setIncreased(false)
      }
    } else {
      setIncreased(null)
      setPercentChange(undefined)
    }

    if (hoveredIndex === -1) {
      setActiveBarData((prev: any) => {
        return { ...prev, totalNet: 0, date: "" }
      })
    } else if (hoveredIndex !== -1) {
      setActiveBarData((prev: any) => {
        return {
          ...prev,
          totalNet: Number(chartData[hoveredIndex]?.totalNet || 0).toFixed(2),
          date: chartData[hoveredIndex].date
        }
      })
    }
  }, [hoveredIndex])

  useEffect(() => {
    setHoveredIndex(-1)

    const getAllSummary = async (filter: KSelectOption, suboption: KSelectOption) => {
      if (chosenMonth && selectedSubOption && filter.value2) {
        const endDate = calculateLastMonth(chosenMonth).replace("-", "")
        const startDate = chosenMonth.replace("-", "")
        let responseData
        if (filter.value2 === "topTracks" && suboption.value === -2) {
          //value:-2 belongs to All Tracks label
          responseData = await getSummaries("platform", "all_platforms", false, startDate, endDate)
        } else {
          responseData = await getSummaries(
            filter.value2,
            suboption.value2 || suboption.value,
            false,
            startDate,
            endDate
          )
        }

        setData(mapListToCamelCase(responseData))
      }
    }
    if (selectedSubOption && chosenMonth) {
      getAllSummary(selectedFilterOption, selectedSubOption).then(() => {})
    }
  }, [chosenMonth, selectedSubOption])

  useEffect(() => {
    if (initialData) {
      setData(initialData)
    }
  }, [initialData])

  useEffect(() => {
    const subOptionSetting = async () => {
      if (filterOptions && selectedFilterOption) {
        setLoading(true)

        await subOptionsSetter(selectedFilterOption, filterOptions, setSubOptions)
        setLoading(false)
      }
    }
    if (filterOptions && selectedFilterOption) {
      subOptionSetting()
    }
  }, [filterOptions, selectedFilterOption])

  useEffect(() => {
    if (!loading) {
      setSelectedSubOption(subOptions[0])
    }
  }, [loading])

  useEffect(() => {
    if (data) {
      const maxTotalNet = Math.max(...data.map((item) => Number(item.totalNet)))
      if (Number(maxTotalNet.toFixed(2)) > 0) {
        setMaxValue(maxTotalNet)
      } else if (Number(maxTotalNet.toFixed(2)) === 0) {
        setMaxValue(1)
      }

      const dateList = data.map((summary) => summary.date)
      const newDatas: { date: string; totalNet: number }[] = []
      //const filteredMonths: string[] = getFilteredMonths(dateList)
      const start_date = props.defaultStartDate
      const start_date_year = start_date.getFullYear()
      const start_date_month = String(start_date.getMonth() + 1).padStart(2, "0")
      const months = generateMonthRange(
        chosenMonth ? chosenMonth : `${start_date_year}-${start_date_month}`,
        props.monthRange
      )
      months.forEach((month, index) => {
        const summaryTotal = data
          .filter((summary) => summary.date === month)
          .reduce((accumulator, currentValue) => accumulator + Number(currentValue.totalNet), 0)

        newDatas.push({ date: month, totalNet: summaryTotal || 0 })
      })
      setChartData(newDatas)

      let total = 0
      for (let i = 0; i < data.length; i++) {
        total += Number(data[i].totalNet)
      }
      setTotal(parseFloat(total.toFixed(2)))
    }
  }, [data])

  const calculateLastMonth = (dateString: string): string => {
    const [year, month] = dateString.split("-").map(Number)

    const finalDate = new Date(year, month - 1)
    finalDate.setMonth(finalDate.getMonth() + (props.monthRange - 1))

    const finalYear = finalDate.getFullYear()
    const finalMonth = String(finalDate.getMonth() + 1).padStart(2, "0")

    return `${finalYear}-${finalMonth}`
  }

  return (
    <div className="w-full flex flex-col gap-3">
      <div className="flex flex-row justify-between gap-3">
        {!loading && (
          <KDropdown
            key={`selected-option-${selectedSubOption}`}
            width={200}
            height={48}
            options={subOptions}
            padding="8px 14px"
            isEllipsis
            defaultValue={subOptions[0]}
            selected={selectedSubOption}
            allowContainerShrink={true}
            menuWidth={200}
            enableIndicator={true}
            rightIcon="/analytics_icons/caret-down-new.svg"
            onSelect={(selected: KSelectOption | any) => {
              setSelectedSubOption(selected)
              if (!chosenMonth) {
                const start_date = props.defaultStartDate
                const year = start_date.getFullYear()
                const month = String(start_date.getMonth() + 1).padStart(2, "0")
                setChosenMonth(`${year}-${month}`)
              }
              //selectFilter(selected.value2)
            }}
            shadowDisabled={true}
          />
        )}

        <div className="flex flex-row gap-2 items-center">
          <div className="p-3.5 bg-[#F7F7F7] rounded-[10px] gap-1 h-12 flex items-center">
            <KSpan text={dateLabels.start} color="#000" fontWeight={500} />
            <KSpan text={`-`} color="#000" fontWeight={500} />
            <KSpan text={dateLabels.end} color="#000" fontWeight={500} />
          </div>
          <KSelectDate
            onChange={(event) => {
              if (!event) {
                const start_date = props.defaultStartDate
                const year = start_date.getFullYear()
                const month = String(start_date.getMonth() + 1).padStart(2, "0")
                setChosenMonth(`${year}-${month}`)
                setDateValue(undefined)
              } else if (event && event.getTime() !== dateValue?.getTime()) {
                const year = event?.getFullYear()
                const month = String(event?.getMonth() + 1).padStart(2, "0")
                setChosenMonth(`${year}-${month}`)
                setDateValue(event)
              }
              if (!selectedSubOption) {
                setSelectedSubOption(subOptions[0])
              }
            }}
            value={dateValue}
            hideBody
            onlyMonthSelection
            icon={"/analytics_icons/calendar.svg"}
            width="48px"
            height="48px"
            backgroundColor="#F7F7F7"
            hoverBackgroundColor="#F3F3F3"
            boxShadow="none"
            anchorToButton
            align="left"
          />
        </div>
      </div>
      <div
        className="flex flex-row w-full bg-[#FFF] p-5 rounded-[10px] h-full"
        style={{
          border: "1px solid #F3F3F3",
          boxShadow: "0px 2px 4px 0px rgba(27, 28, 29, 0.04)"
        }}
      >
        <div className="flex flex-col  justify-between w-[25%]">
          <div className="flex justify-between items-center">
            {
              <div className=" flex items-center justify-between">
                <div className="flex flex-row items-center p-3.5 gap-1 whitespace-nowrap">
                  <img src="/analytics_icons/bar-chart-square.svg" />
                  <KSpan text={lang.analytics.overview} color="#000" fontWeight={500} />
                </div>
              </div>
            }
          </div>
          <div className="flex flex-col gap-2.5 pl-3">
            <div className="h-auto flex flex-row justify-start items-center gap-2 p-0">
              {currency && (
                <div className="flex items-center">
                  <KTitleSpan
                    text={getCurrency(currency)}
                    fontSize={32}
                    lineHeight="40px"
                    letterSpacing="-0.4px"
                    color="#000"
                  />
                </div>
              )}
              <div className="flex items-center">
                <KTitleSpan
                  //text={hoveredIndex === -1 ? `${total}` : `${activeBarData.totalNet}`}
                  text={`${addCommasToNumber(total.toFixed(0))}`}
                  fontSize={48}
                  lineHeight="56px"
                  letterSpacing="-0.48px"
                  color="#000"
                />
              </div>
            </div>

            <div className="h-8">
              {percentChange && (
                <div className="flex flex-row items-center gap-1">
                  {" "}
                  <img
                    src={percentChange > 0 ? "/analytics_icons/caret-up.svg" : "/analytics_icons/caret-down.svg"}
                    alt="change-icon"
                    height={16}
                    width={16}
                  />
                  <KSpan text={`${percentChange}%`} color={percentChange > 0 ? "#56E099" : "#FF5865"} />
                  <KSpan
                    text={lang.analytics.last_month_comparison_desc}
                    color="#999"
                    fontSize={12}
                    lineHeight="16px"
                    fontWeight={500}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-[9px] w-[75%] ">
          <div
            className="m-auto h-full  w-full"
            onMouseLeave={() => {
              setHoveredIndex(-1)
            }}
            style={{ minHeight: 250 }}
          >
            {chartData.length > 0 && (
              <Bar
                data={{
                  labels: chartData.map((summary: AnalysisSummary | myData) => convertToMonthYear(summary.date, true)),
                  datasets: [
                    {
                      data: chartData.map((summary: AnalysisSummary | myData) =>
                        Number(Number(summary.totalNet).toFixed(2)) > 0
                          ? Number(Number(summary.totalNet).toFixed(2))
                          : 0
                      ),
                      backgroundColor: chartData.map((summary: AnalysisSummary | myData, index: number) =>
                        Number(Number(summary.totalNet).toFixed(2)) > 0
                          ? hoveredIndex === index
                            ? "#000"
                            : "#E7E7E7"
                          : hoveredIndex === index
                          ? "transparent"
                          : "transparent"
                      ),
                      barThickness: "flex",
                      borderSkipped: false,
                      datalabels: {
                        display: false,
                        anchor: (context) => {
                          return calculateChartPercentage(context) > 5 ? "center" : "end"
                        },
                        align: "end",
                        offset: (context) => {
                          const pc = calculateChartPercentage(context)
                          return pc > 5 ? 0 : pc > 4 ? -70 : pc > 3 ? -45 : -30
                        }
                      }
                    }
                  ]
                }}
                options={{
                  onHover: (event, elements, chart) => {
                    if (elements.length > 0) {
                      const clicked = elements[0].index
                      setHoveredIndex(clicked)
                    } else {
                      setHoveredIndex(-1)
                    }
                  },
                  scales: {
                    x: {
                      display: true, // Enable x-axis display,
                      border: {
                        display: false
                      },
                      grid: {
                        display: false
                      },
                      ticks: {
                        display: true, // Show x-axis ticks (e.g., months)
                        color: chartData.map((summary: AnalysisSummary | myData, index: number) =>
                          hoveredIndex === index ? "#000" : "#666"
                        ),
                        font: {
                          family: "Inter",
                          weight: 500,
                          size: 14,
                          lineHeight: "20px"
                        }
                      }
                    },
                    y: {
                      display: false,
                      grid: {
                        display: false
                      },
                      ticks: {
                        display: false
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    },
                    datalabels: {
                      display: false,
                      formatter: (value, ctx) => {
                        //@ts-ignore
                        const sum = ctx.chart.data.datasets[0].data.reduce((a: number, b: number) => a + b, 0) as number
                        return `${((value * 100) / sum).toFixed(1)}%`
                      }
                    },
                    tooltip: {
                      displayColors: false,
                      backgroundColor: "#000",
                      enabled: true,
                      xAlign: "center",
                      yAlign: "bottom",
                      caretSize: 0,
                      caretPadding: 10,
                      bodyFont: {
                        family: "Inter",
                        weight: 400,
                        size: 14,
                        lineHeight: "20px"
                      },

                      callbacks: {
                        label: (context) => {
                          let label = context.dataset.label || ""
                          const sign = getCurrency(currency)
                          if (label) {
                            label += ": "
                          }
                          if (context.parsed.y !== null) {
                            //label += ` ${sign}${addCommasToNumber(context.parsed.y.toFixed(2))}` It can be like this but below one is more reliable
                            label += `${sign}${addCommasToNumber(
                              Number(chartData[context.dataIndex].totalNet).toFixed(2)
                            )}`
                          }
                          return label
                        },

                        title: (context) => ""
                      }
                    }
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  elements: {
                    bar: {
                      borderRadius: {
                        topRight: 10,
                        topLeft: 10,
                        bottomRight: 10,
                        bottomLeft: 10
                      },
                      backgroundColor: "#ff0"
                    }
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state: RootState) => {
  return {
    totals: state.analysis.totals,
    currency: state.auth.user.preferredCurrency,
    user: state.auth.user,
    selectedDate: state.analysis.selectedDate,
    selectedFilter: state.analysis.selectedFilter,
    summaries: state.analysis.summaries,
    filterOptions: state.analysis.filterOptions
  }
}

export default connect(mapStateToProps, { fetchSummaries, selectFilter, fetchFilterOptions, fetchAllSummaries })(
  AnalysisChart
)
