import React, { FC, useEffect, useRef, useState } from "react"
import {
  AssetRegistryPublishingShareHolder,
  AssetRegistryTrackShareHolder,
  LCReleaseForm,
  LCReleaseFormData,
  SelectOption,
  TrackRegistryConsentFile,
  TrackUploadRequest,
  User
} from "../../../../../types"
//@ts-ignore
import DraggableIcon from "../../../../../assets/release-registry-icons/draggable.svg"
import "../../styles/Catalog.css"
import { Plus, PlayOutline, Upload } from "heroicons-react"
import TrackDetail from "./TrackDetail"
import { releases } from "../../../../../apis"
import { toast } from "react-toastify"
import { errorFilledToastOptions, successFilledToastOptions, USER_ROLE } from "../../../../../constants"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { AssetRegistryPages } from "../../../../../actions/types"
import Loading from "../../../../ui/Loading"
import LoaderLine from "../../../../ui/LoaderLine"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { getDuration, mapListToSnakeCase, mapToCamelCase, mapToSnakeCase } from "../../../../../utility"
import { DragDropContext, Droppable, Draggable, DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { lang } from "../../../../../constants/languages"
//@ts-ignore
import DownloadIcon from "../../../../../assets/release-registry-icons/download.svg"
import { useLocation } from "react-router-dom"

interface TracksAndAssetsProps {
  releaseForm: LCReleaseForm
  setReleaseForm: (releaseForm: LCReleaseForm) => void
  options: any
  setSelectedTab: (selectedTab: number) => void
  setErrorText: (errorText?: string) => void
  artistOptions: SelectOption[]
  setArtistOptions: (artistOptions: SelectOption[]) => void
  onSearchArtist: (search: string) => void
  selectedArtistList: string[]
  setSelectedArtistList: (selectedArtistList: string[]) => void
  lyricsWidget: any
  setNavigationZIndex: (navigationZIndex: number) => void
  applyArtistToAll: (key: string, values: string[]) => void
  trackShareHolderRoleOptions: KSelectOption[]
  isPublishing: boolean
  user: User
  saveTrack: (trackToUpdate: LCReleaseFormData) => void
}

const TracksAndAssets: FC<TracksAndAssetsProps> = (props) => {
  const {
    releaseForm,
    setReleaseForm,
    setSelectedTab,
    artistOptions,
    setArtistOptions,
    onSearchArtist,
    setSelectedArtistList,
    selectedArtistList,
    setErrorText,
    lyricsWidget,
    setNavigationZIndex,
    applyArtistToAll,
    trackShareHolderRoleOptions,
    user,
    isPublishing,
    saveTrack
  } = props

  const fileInputRef = useRef(null)

  const [selectedTrack, setSelectedTrack] = useState<LCReleaseFormData>()
  const [selectedTrackOffer, setSelectedTrackOffer] = useState<LCReleaseFormData>()
  const [trackShareHolderList, setTrackShareHolderList] = useState<AssetRegistryTrackShareHolder[]>([])
  const [publishingShareHolderList, setPublishingShareHolderList] = useState<AssetRegistryPublishingShareHolder[]>([])
  const [rotorClassicAccessToken, setRotorClassicAccessToken] = useState<string>()
  const [rotorSpotifyAccessToken, setRotorSpotifyAccessToken] = useState<string>()
  const [trackRegistryConsentFileList, setTrackRegistryConsentFileList] = useState<TrackRegistryConsentFile[]>([])
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [selectedAudioTrack, setSelectedAudioTrack] = useState<LCReleaseFormData>()
  const [activeImportTaskIdList, setActiveImportTaskIdList] = useState<string[]>([])
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>()
  const [processingIntervalId, setProcessingIntervalId] = useState<NodeJS.Timer>()
  const [uploadDisabled, setUploadDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [processingTrackIds, setProcessingTrackIds] = useState<string[]>([])
  const [audioSrcMap, setAudioSrcMap] = useState({})
  const [driveLinkExist, setDriveLinkExist] = useState(false)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (searchParams && searchParams.get("drive")) {
      setDriveLinkExist(true)
    }
  }, [searchParams])

  const nextStep = () => {
    setSelectedTab(AssetRegistryPages.TERRITORY_AND_PLATFORM_RIGHTS)
  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setSelectedTrack(undefined)
        setTrackShareHolderList([])
        setPublishingShareHolderList([])
        setRotorClassicAccessToken(undefined)
        setRotorSpotifyAccessToken(undefined)
        setTrackRegistryConsentFileList([])
      }
    }

    document.addEventListener("keydown", handleEsc)

    return () => {
      document.removeEventListener("keydown", handleEsc)
    }
  }, [])

  const importTaskInterval = async () => {
    const newIntervalId = setInterval(async () => {
      let newTaskIdList = [...activeImportTaskIdList]
      let newProcessingTrackIds = [...processingTrackIds]
      for (let i = 0; i < activeImportTaskIdList.length; i++) {
        await releases(`/release/label_camp_import_task/?import_task_id=${activeImportTaskIdList[i]}`, "get").then(
          (resp) => {
            try {
              const data = resp.data.result.data
              const trackId = data.relationships?.track?.data?.id || ""
              if (data.attributes.status === "error") {
                const foundTrack = releaseForm.tracks.find(
                  (track) => track.data.id === data.relationships.track.data.id
                )
                setErrorText(
                  `${lang.catalog.asset_registry.tracks_and_assets.error_text.error_on_audio_upload} ${foundTrack?.data?.attributes?.title}\n\n ${data.attributes.message}`
                )
                newProcessingTrackIds = [...newProcessingTrackIds.filter((id) => id !== trackId)]
                newTaskIdList = [...newTaskIdList.filter((id) => id !== data.id)]
              } else if (data.attributes.status === "completed") {
                const updatedTracks = releaseForm.tracks.map((track: LCReleaseFormData) => {
                  if (track.data.id === data.relationships.track.data.id) {
                    toast.success(
                      lang.catalog.asset_registry.tracks_and_assets.success.audio_upload.replace(
                        "<param>",
                        track.data.attributes.title
                      ),
                      successFilledToastOptions
                    )
                    return {
                      ...track,
                      data: {
                        ...track.data,
                        attributes: {
                          ...track.data.attributes,
                          "preview-url": data.attributes.url,
                          audio16bits: data.attributes.precision === "16",
                          audio24bits: data.attributes.precision === "24",
                          "audio-dolby-atmos": data.attributes.precision === "dolby_atmos"
                        }
                      }
                    }
                  }
                  return track
                })
                setReleaseForm({
                  ...releaseForm,
                  tracks: [...updatedTracks]
                })
                newProcessingTrackIds = [...newProcessingTrackIds.filter((id) => id !== trackId)]
                newTaskIdList = [...newTaskIdList.filter((id) => id !== data.id)]
              }
            } catch (e) {
              // Do Nothing
            }
          }
        )
      }
      setActiveImportTaskIdList(newTaskIdList)
      setProcessingTrackIds(newProcessingTrackIds)
    }, 25000)
    setIntervalId(newIntervalId)
  }

  useEffect(() => {
    clearInterval(intervalId)
    if (activeImportTaskIdList.length === 0) {
      return
    }
    importTaskInterval()
  }, [activeImportTaskIdList])

  const checkProcessingTaskExist = (processingTasks) => {
    const track_id_list = releaseForm.tracks.map((track) => track.data.id)
    releases(
      `/release/label_camp_upload_track_audio_status/?track_id_list=${track_id_list}&product_id=${releaseForm.product.data.id}`,
      "get"
    ).then((resp) => {
      let trackIds: string[] = []
      if (processingTasks.length > 0) {
        trackIds = processingTasks.map((task) => task.data.relationships?.track?.data?.id)

        const taskIds = processingTasks.map((task) => task.data.id || "-")
        setActiveImportTaskIdList([...taskIds])
      }

      for (let i = 0; i < resp.data.length; i++) {
        const element = resp.data[i]
        if (element.status === "in_progress") {
          trackIds.push(element.track_id)
        }
      }

      setProcessingTrackIds([...trackIds])
    })
  }

  const processingTaskInterval = async () => {
    const newIntervalId = setInterval(async () => {
      releases(
        `/release/label_camp_upload_track_audio_status/?track_id_list=${processingTrackIds}&product_id=${releaseForm.product.data.id}`,
        "get"
      ).then((resp) => {
        let isUpdatedTask = false
        let isUpdatedTrack = false
        let newProcessingTrackIds = [...processingTrackIds]
        const newImportTaskIdList = [...activeImportTaskIdList]
        for (let i = 0; i < resp.data.length; i++) {
          const element = resp.data[i]
          if (element.status === "success" && !newImportTaskIdList.includes(element.import_task_id)) {
            newImportTaskIdList.push(element.import_task_id)
            isUpdatedTask = true
          }
          if (element.status === "failed") {
            newProcessingTrackIds = [...newProcessingTrackIds.filter((id) => id !== element.track_id)]
            isUpdatedTrack = true
            setErrorText(
              `${lang.catalog.asset_registry.tracks_and_assets.error_text.error_on_upload} ${element.track_id}`
            )
          }
        }

        if (isUpdatedTask) {
          setActiveImportTaskIdList([...newImportTaskIdList])
        }
        if (isUpdatedTrack) {
          setProcessingTrackIds([...newProcessingTrackIds])
        }
      })
    }, 30000)
    setProcessingIntervalId(newIntervalId)
  }

  useEffect(() => {
    clearInterval(processingIntervalId)
    if (processingTrackIds.length === 0) {
      return
    }
    processingTaskInterval()
  }, [processingTrackIds])

  useEffect(() => {
    const processingList =
      releaseForm.import_tasks?.filter((task) => task.data?.attributes?.status === "processing") || []
    const processingTasks = [...processingList]

    checkProcessingTaskExist(processingTasks)

    const completedList =
      releaseForm.import_tasks?.filter((task) => task.data?.attributes?.status === "completed") || []
    const completedTasks = [...completedList]

    const audioSrcMap = completedTasks.reduce((acc, task) => {
      const key = task.data?.relationships?.track?.data?.id || "-"
      const value = task.data?.attributes?.url || "-"
      acc[key] = value
      return acc
    }, {})
    setAudioSrcMap(audioSrcMap)
  }, [])

  useEffect(() => {
    setNavigationZIndex(selectedTrack ? -1 : 0)
  }, [selectedTrack])

  const handleButtonClick = (track: LCReleaseFormData) => {
    setUploadDisabled(true)
    setSelectedAudioTrack(track)
    if (!fileInputRef?.current) {
      return
    }
    //@ts-ignore
    fileInputRef.current.click()
  }

  const handleFileChange = (e: any) => {
    if (!selectedAudioTrack) {
      setUploadDisabled(false)
      return
    }

    const file = e.target.files[0]
    if (file) {
      try {
        const formData = new FormData()
        formData.append("file", file)
        setLoading(true)
        releases(
          `/release/label_camp_upload_track_audio/?product_id=${releaseForm.product.data.id}&track_id=${selectedAudioTrack.data.id}`,
          "post",
          formData
        ).then((resp) => {
          setLoading(false)
          if (resp && resp.data && resp.data.status) {
            toast.success(
              lang.catalog.asset_registry.tracks_and_assets.success.upload_started,
              successFilledToastOptions
            )

            processingTrackIds.push(selectedAudioTrack.data.id || "")
            setProcessingTrackIds([...processingTrackIds])
          } else {
            setErrorText(resp.data.message)
          }
          setUploadDisabled(false)
        })
      } catch (error) {
        setErrorText(error)
        setUploadDisabled(false)
        setLoading(false)
      }
    } else {
      console.log("No file selected")
      setUploadDisabled(false)
    }
  }

  const getAudio = (track: LCReleaseFormData) => {
    return (
      <div className={"flex"}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => handleFileChange(e)}
          accept="audio/x-flac, audio/flac, audio/x-wav, audio/x-aiff, audio/aiff, audio/x-wav; charset=binary, audio/x-aifc"
        />
        <button disabled={uploadDisabled} onClick={() => handleButtonClick(track)}>
          <Upload className="cursor-pointer mr-4" />
        </button>
        {track?.data?.attributes["preview-url"] ? (
          <audio controls style={{ width: 120, height: 26 }}>
            <source src={track?.data?.attributes["preview-url"]} type="audio/mp3" />
          </audio>
        ) : (
          <PlayOutline className="text-gray-300 ml-3" />
        )}
      </div>
    )
  }

  const onSelectTrackResponse = (trackOffers, track_offer, track) => {
    trackOffers.push(track_offer)

    setSelectedTrack(track)
    setSelectedTrackOffer(track_offer)
    setReleaseForm({
      ...releaseForm,
      track_offers: [...trackOffers]
    })
  }

  const onSelectTrack = (track: LCReleaseFormData) => {
    if (releaseForm.product.data.relationships["product-type"]?.data?.id === "2") {
      // Selected Single
      track.data.attributes.title = track?.data?.attributes?.title || releaseForm.product.data.attributes.name
      track.data.attributes.subtitle = track?.data?.attributes?.subtitle || releaseForm.product.data.attributes.subtitle
    }
    track.data.attributes.composer = track.data.attributes.composer || []
    if (!track.data.relationships["audio-language"]?.data) {
      track.data.relationships["audio-language"] = releaseForm.product.data.relationships.language
    }

    if (!track?.data.attributes["production-line"]) {
      track.data.attributes["production-line"] = releaseForm.product.data.attributes["production-line"]
    }

    releases(`/track-share-holder-invitation/list_by_isrc/?isrc=${track.data.attributes["isrc-code"]}`, "get").then(
      (resp) => {
        if (resp.data) {
          setTrackShareHolderList(resp.data)
        }
      }
    )

    releases(`/publishing-share-holder/list_by_isrc/?isrc=${track.data.attributes["isrc-code"]}`, "get").then(
      (resp) => {
        if (resp.data) {
          setPublishingShareHolderList(resp.data)
        }
      }
    )

    releases(`/track-registry-consent-file/list_by_isrc/?isrc=${track.data.attributes["isrc-code"]}`, "get").then(
      (resp) => {
        if (resp.data) {
          setTrackRegistryConsentFileList(resp.data)
        }
      }
    )

    const existedTrackOffer = releaseForm.track_offers?.find(
      (offer) => offer.data?.relationships?.track?.data?.id === track.data.id
    )
    if (existedTrackOffer) {
      setSelectedTrack(track)
      setSelectedTrackOffer(existedTrackOffer)
      return
    }

    setLoading(true)
    releases(`/release/label_camp_track_offer/?track_id=${track.data.id}`, "get").then((resp) => {
      setLoading(false)
      const trackOffers = [...releaseForm.track_offers]
      let track_offer: LCReleaseFormData = resp.data.result.track_offer
      if (!track_offer || !track_offer.data) {
        track_offer = {
          data: {
            id: undefined,
            attributes: {
              usage: { download: true, on_demand_stream: true, non_interactive_stream: true, copy_scan: true }
            },
            relationships: {
              track: { data: { type: "tracks", id: track.data.id } },
              offer: { data: { type: "offers", id: releaseForm.offer.data.id } },
              "price-code": { data: { type: "distributor-price-codes", id: "35136" } },
              genre: {
                data: {
                  type: "distributor-product-subgenres",
                  id: releaseForm.offer.data.relationships["subgenre"]?.data?.id
                }
              }
            },
            type: "track-offers"
          }
        }

        const requestBody = {
          track: null,
          offer: {
            ...track_offer
          }
        }
        releases(`/release/label_camp_track_create/`, "post", requestBody).then((resp) => {
          const track_offers = [...releaseForm.track_offers]
          track_offers.push(resp.data.result.offer)
          setReleaseForm({ ...releaseForm, track_offers: [...track_offers] })

          onSelectTrackResponse(trackOffers, resp.data.result.offer, track)
        })
      } else {
        onSelectTrackResponse(trackOffers, track_offer, track)
      }
    })
  }

  const createTrack = () => {
    setLoading(true)
    const trackNumber = releaseForm.tracks?.length || 0
    const requestBody = {
      track: {
        data: {
          type: "tracks",
          attributes: { "volume-number": 1, "track-number": trackNumber + 1 },
          relationships: { product: { data: { id: releaseForm.product.data.id, type: "products" } } }
        }
      }
    }
    releases(`/release/label_camp_track_create/`, "post", requestBody).then((resp) => {
      setLoading(false)
      if (!resp.data.success) {
        setErrorText(resp.data.result)
        return
      }
      const tracks = [...releaseForm.tracks]
      tracks.push(resp.data.result.track)
      setReleaseForm({ ...releaseForm, tracks: [...tracks] })
    })
  }

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const deleteTracks = async () => {
    setLoading(true)
    const deletedList: string[] = []
    const errorList: string[] = []
    for (let i = 0; i < checkedList.length; i++) {
      const trackToDelete = releaseForm.tracks.find((track) => track.data.id === checkedList[i])
      const isrc = trackToDelete?.data.attributes["isrc-code"]
      await releases(`/release/label_camp_track_delete/?track_id=${checkedList[i]}&isrc=${isrc}`, "delete").then(
        async (resp) => {
          if (resp.data.success) {
            deletedList.push(checkedList[i])

            let trackNumber = 1
            for (const track of releaseForm.tracks) {
              if (track.data.id !== checkedList[i] && !deletedList.includes(track.data.id || "")) {
                track.data.attributes["track-number"] = trackNumber
                saveTrack(track)
                await delay(1000) // Wait for 2 seconds
                trackNumber++
              }
            }
          } else {
            errorList.push(resp.data.result)
          }
        }
      )
    }
    setCheckedList([])
    setReleaseForm({
      ...releaseForm,
      tracks: releaseForm.tracks.filter((track) => !deletedList.includes(track.data.id!!))
    })
    setLoading(false)

    if (errorList.length > 0) {
      setErrorText(errorList.join("\n"))
    }
  }

  const renderAssets = (track: LCReleaseFormData) => {
    const opacity16Bit = track.data.attributes?.audio16bits ? "1" : "0.2"
    const opacityis24Bit = track.data.attributes?.audio24bits ? "1" : "0.2"
    const opacityisDolby = track.data.attributes?.["audio-dolby-atmos"] ? "1" : "0.2"
    return (
      <div>
        <div className="flex items-center gap-1">
          <div style={{ background: `rgba(33, 164, 148, ${opacity16Bit})`, borderRadius: 5 }} className="pl-1 pr-1">
            <KSpan text="16-Bit" color="white" />
          </div>
          <div style={{ background: `rgba(187, 204, 19, ${opacityis24Bit})`, borderRadius: 5 }} className="pl-1 pr-1">
            <KSpan text="24-Bit" color="white" />
          </div>
          <div style={{ background: `rgba(48, 39, 44, ${opacityisDolby})`, borderRadius: 5 }} className="p-1">
            <img width={40} height={28} src={"/catalog_icons/dolby.svg"}></img>
          </div>
        </div>
        {processingTrackIds.includes(track.data.id?.toString() || "-") && (
          <div style={{ width: 150 }} className="mt-3">
            <LoaderLine />
          </div>
        )}
      </div>
    )
  }

  const onDragEnd = async (result) => {
    if (!result.destination) return
    const reorderedTracks = Array.from(releaseForm.tracks)
    const [movedTrack] = reorderedTracks.splice(result.source.index, 1)
    reorderedTracks.splice(result.destination.index, 0, movedTrack)

    setLoading(true)
    let trackNumber = 1
    for (const track of reorderedTracks) {
      track.data.attributes["track-number"] = trackNumber
      saveTrack(track)
      await delay(1000) // Wait for 2 seconds
      trackNumber++
    }

    setLoading(false)

    setReleaseForm({ ...releaseForm, tracks: reorderedTracks })
  }

  const onImportFromDrive = async () => {
    const driveLink = window.prompt("Please enter drive link")

    if (!driveLink) {
      return
    }

    const trackUploadRequestList: TrackUploadRequest[] = []
    setLoading(true)
    releases(`/release/import_audio_asset_list/?drive_link=${driveLink}`, "get").then(async (resp) => {
      if (resp?.data?.length > 0) {
        const releaseTracks = [...releaseForm.tracks]
        const productId = releaseForm.product.data.id

        for (let i = 0; i < resp.data.length; i++) {
          const track: TrackUploadRequest = mapToCamelCase(resp.data[i])
          const trackNameSplit = track.title.split(/-(.+)/)
          const trackNumber = parseInt(trackNameSplit[0].trim())
          const trackTitle = trackNameSplit[1].split(".")[0].trim()
          const extension = trackNameSplit[1].split(".")[1]

          const requestBody = {
            track: {
              data: {
                relationships: {
                  product: { data: { id: productId, type: "products" } }
                },
                attributes: {
                  "volume-number": 1,
                  "track-number": trackNumber,
                  title: trackTitle
                },
                type: "tracks"
              }
            }
          }

          const trackResponse = await releases(`/release/label_camp_track_create/`, "post", requestBody)

          if (!trackResponse.data.success) {
            toast.error(
              `${lang.catalog.new_release.error.track_response}: ${JSON.stringify(trackResponse.data.result)}`,
              errorFilledToastOptions
            )
          }
          releaseTracks.push(trackResponse.data.result.track)
          trackUploadRequestList.push({
            ...track,
            title: trackTitle,
            productId: productId || "",
            trackId: trackResponse.data.result.track.data.id,
            extension
          })

          processingTrackIds.push(trackResponse.data.result.track.data.id)
        }

        toast.success("Tracks saved successfully, Started downloading audio assets.", successFilledToastOptions)
        setReleaseForm({ ...releaseForm, tracks: [...releaseTracks] })

        const uploadResponse = await releases(
          `/release/import_audio_assets/`,
          "post",
          mapListToSnakeCase(trackUploadRequestList)
        )

        if (uploadResponse.data.success) {
          toast.success("Upload started successfully.", successFilledToastOptions)
          setProcessingTrackIds([...processingTrackIds])
        } else {
          toast.success("Error uploading audio assets.", errorFilledToastOptions)
        }

        setLoading(false)
      } else {
        toast.error("Error importing track list", errorFilledToastOptions)
        setLoading(false)
      }
    })
  }

  return (
    <div>
      {loading && <Loading />}
      <div className={"flex"}>
        <div className={"m-4 p-4 bg-white mt-0"} style={{ width: "100%" }}>
          <div className={"flex justify-between mb-6"}>
            <div className={"flex"}>
              <KTitleSpan text={lang.catalog.asset_registry.tracks_and_assets.track} lineHeight="auto" fontSize={18} />
              <span className={"rounded-2xl ml-3 text-white pl-2 pr-2"} style={{ background: "black" }}>
                <KSpan text={releaseForm.tracks?.length?.toString() || "0"} color="white" />
              </span>
              <Plus className="w-6 h-6 ml-2 text-gray-500 cursor-pointer" onClick={() => createTrack()} />
            </div>
            {(props.user.roleId === USER_ROLE.SuperAdmin ||
              props.user.roleId === USER_ROLE.Admin ||
              driveLinkExist) && (
              <div>
                <KButton
                  gap="4px"
                  leftIcon={DownloadIcon}
                  height="20px"
                  text="Import Audio Assets"
                  onClick={() => onImportFromDrive()}
                  background="transparent"
                  shadowDisabled={true}
                />
              </div>
            )}
          </div>
          <div className={"pb-6 flex border-t border-b pt-6"}>
            <div style={{ width: "3%" }} />
            <div style={{ width: "3%" }}>
              <input
                style={{ cursor: "pointer", accentColor: "black", marginTop: 6.5 }}
                type="checkbox"
                checked={releaseForm.tracks?.length === checkedList.length}
                onChange={() => {
                  checkedList.length > 0
                    ? setCheckedList([])
                    : setCheckedList(releaseForm.tracks.map((track) => track.data?.id!!))
                }}
              />
            </div>
            <div style={{ width: "4%" }}>
              <KSpan text={"#"} />
            </div>
            <div style={{ width: "20%" }}>
              <KSpan text={lang.catalog.asset_registry.tracks_and_assets.title} />
            </div>
            <div style={{ width: "20%" }}>
              <KSpan text={lang.catalog.asset_registry.tracks_and_assets.main_artist} />
            </div>
            <div style={{ width: "15%" }}>
              <KSpan text={"ISRC"} />
            </div>
            <div style={{ width: "11%" }}>
              <KSpan text={lang.catalog.asset_registry.tracks_and_assets.duration} />
            </div>
            <div style={{ width: "16%" }}>
              <KSpan text={lang.catalog.asset_registry.tracks_and_assets.assets} />
            </div>
            <div style={{ width: "8%" }}>
              <KSpan text={lang.catalog.asset_registry.tracks_and_assets.audio} />
            </div>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="tracksList">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`flex flex-col max-h-[45vh] mb-8 ${releaseForm.tracks.length > 0 ? "" : "py-5"}`}
                >
                  {releaseForm.tracks.map((track, i) => {
                    return (
                      <Draggable key={track.data.id} draggableId={track.data?.id?.toString()} index={i}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} className="flex flex-col">
                            <div
                              className={"flex border-b pt-6 pb-6 hover-div"}
                              key={track.data.id}
                              onClick={() => onSelectTrack(track)}
                            >
                              <div style={{ width: "3%" }}>
                                <img
                                  src={DraggableIcon}
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    rounded: "10px"
                                  }}
                                  className="hover:bg-[#F0F0F0]"
                                  {...provided.dragHandleProps}
                                />
                              </div>
                              <div style={{ width: "3%" }} onClick={(e) => e.stopPropagation()}>
                                <input
                                  style={{ cursor: "pointer", accentColor: "black", marginTop: 6.5 }}
                                  type="checkbox"
                                  checked={checkedList.includes(track.data?.id!!)}
                                  onChange={() => {
                                    const trackId = track.data?.id
                                    const isChecked = checkedList.includes(track.data?.id!!)
                                    if (isChecked) {
                                      setCheckedList([...checkedList.filter((checkId) => checkId !== trackId)])
                                    } else {
                                      checkedList.push(trackId!!)
                                      setCheckedList([...checkedList])
                                    }
                                  }}
                                />
                              </div>
                              <div style={{ width: "4%" }}>
                                <KSpan text={track.data?.attributes["track-number"]} color="black" />
                              </div>
                              <div style={{ width: "20%" }}>
                                <KSpan
                                  text={
                                    track.data?.attributes["title"] ||
                                    lang.catalog.asset_registry.tracks_and_assets.empty_track_title
                                  }
                                  color="black"
                                />
                              </div>
                              <div style={{ width: "20%" }}>
                                <KSpan
                                  text={
                                    track.data?.attributes["artist"]?.join(", ") ||
                                    lang.catalog.asset_registry.tracks_and_assets.empty_main_artist
                                  }
                                  color="black"
                                />
                              </div>
                              <div style={{ width: "15%" }}>
                                <KSpan
                                  text={
                                    track.data?.attributes["isrc-code"] ||
                                    lang.catalog.asset_registry.tracks_and_assets.empty_isrc
                                  }
                                  color="black"
                                />
                              </div>
                              <div style={{ width: "8%" }}>
                                <KSpan text={getDuration(track)} color="black" />
                              </div>
                              <div style={{ width: "15%" }}>{renderAssets(track)}</div>
                              <div style={{ width: "12%" }} onClick={(e) => e.stopPropagation()}>
                                {getAudio(track)}
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <TrackDetail
        releaseForm={releaseForm}
        setReleaseForm={setReleaseForm}
        options={props.options}
        selectedTrack={selectedTrack}
        setSelectedTrack={setSelectedTrack}
        selectedTrackOffer={selectedTrackOffer}
        setSelectedTrackOffer={setSelectedTrackOffer}
        artistOptions={artistOptions}
        setArtistOptions={setArtistOptions}
        onSearchArtist={onSearchArtist}
        selectedArtistList={selectedArtistList}
        setSelectedArtistList={setSelectedArtistList}
        setErrorText={setErrorText}
        lyricsWidget={lyricsWidget}
        audioSrcMap={audioSrcMap}
        applyArtistToAll={applyArtistToAll}
        trackShareHolderRoleOptions={trackShareHolderRoleOptions}
        trackShareHolderList={trackShareHolderList}
        setTrackShareHolderList={setTrackShareHolderList}
        publishingShareHolderList={publishingShareHolderList}
        setPublishingShareHolderList={setPublishingShareHolderList}
        isPublishing={isPublishing}
        trackRegistryConsentFileList={trackRegistryConsentFileList}
        setTrackRegistryConsentFileList={setTrackRegistryConsentFileList}
        rotorClassicAccessToken={rotorClassicAccessToken}
        setRotorClassicAccessToken={setRotorClassicAccessToken}
        rotorSpotifyAccessToken={rotorSpotifyAccessToken}
        setRotorSpotifyAccessToken={setRotorSpotifyAccessToken}
      />
      <div
        className={"justify-between flex"}
        style={{ marginBottom: 60, marginTop: 20, marginRight: 40, marginLeft: 40 }}
      >
        <div>
          {checkedList.length > 0 && (
            <KButton
              width="400px"
              text={lang.catalog.asset_registry.tracks_and_assets.delete_button}
              onClick={() => deleteTracks()}
              background="red"
              textColor="white"
              disabled={loading}
            />
          )}
        </div>
        <div className={"flex justify-center"}>
          <KButton
            width="400px"
            text={lang.catalog.asset_registry.tracks_and_assets.next_step_button}
            disabled={loading}
            onClick={() => nextStep()}
            background="black"
            textColor="white"
          />
        </div>
      </div>
    </div>
  )
}

export default TracksAndAssets
