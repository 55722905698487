import React, { FC, useEffect, useState } from "react"
import {
  AssetRegistryProps,
  LCReleaseForm,
  LCReleaseFormData,
  SelectOption,
  SpotifyEditorialPitching
} from "../../../../types"
import "../styles/Catalog.css"
import "./styles/AssetRegistry.css"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { RootState } from "../../../../store"
import { connect } from "react-redux"
import { selectRelease } from "../../../../actions/catalogActions"
import { releases } from "../../../../apis"
import { hashids, mapToCamelCase } from "../../../../utility"
import Summary from "./summary/Summary"
import { KButton, KLogo, KSpan, KTitleSpan } from "kahuna-base-react-components"
import NavigationFlow from "./NavigationFlow"
import { AssetRegistryPages } from "../../../../actions/types"
import GeneralInformation from "./general-information/GeneralInformation"
import ErrorModal from "./modal/ErrorModal"
import TerritoryAndPlatformRights from "./territory-and-platform-rights/TerritoryAndPlatformRights"
import TracksAndAssets from "./tracks-and-assets/TracksAndAssets"
import Loading from "../../../ui/Loading"
import useScript from "../../../../utility/useScript"
import { toast } from "react-toastify"
import { USER_PERMISSION, USER_ROLE, successFilledToastOptions, warningFilledToastOptions } from "../../../../constants"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import SpotifyEditorialPitchingPage from "./spotify-editorial-pitching/SpotifyEditorialPitchingPage"
import { fetchUserData } from "../../../../actions"
import { lang } from "../../../../constants/languages"
import { notAllowedTrackAttributes, notAllowedTrackRelationships } from "../../../../utility/newReleaseHelpler"

const headerHeight = 56
const navigationFlowHeight = 76

declare global {
  interface Window {
    LyricFind: any
  }
}

const initReleaseForm: LCReleaseForm = {
  product: {
    data: {
      id: undefined,
      attributes: {
        artist: [],
        composer: []
      },
      relationships: {},
      type: "products"
    }
  },
  offer: {
    data: {
      id: undefined,
      attributes: {},
      relationships: {
        product: {}
      },
      type: "offers"
    }
  },
  tracks: [],
  import_tasks: [],
  import_sub_tasks: [],
  records: [],
  track_offers: [],
  logs: []
}

const AssetRegistry: FC<AssetRegistryProps> = (props) => {
  const { selectRelease, fetchUserData } = props
  const [errorText, setErrorText] = useState<string | undefined>(undefined)
  const [isAdd, setIsAdd] = useState(false)
  const [selectedTab, setSelectedTab] = useState(-1)
  const [releaseForm, setReleaseForm] = useState<LCReleaseForm>(initReleaseForm)
  const [options, setOptions] = useState({})
  const [loading, setLoading] = useState(true)
  const [selectedArtistList, setSelectedArtistList] = useState<string[]>([])
  const [artistOptions, setArtistOptions] = useState<SelectOption[]>([])
  const [timeoutId, setTimeoutId] = useState(null)
  const [lyricsWidget, setLyricsWidget] = useState<any>(null)
  const [navigationZIndex, setNavigationZIndex] = useState(0)
  const [trackShareHolderRoleOptions, setTrackShareHolderRoleOptions] = useState<KSelectOption[]>([])
  const [appleDigitalMaster, setAppleDigitalMaster] = useState<string>("")
  const [isUrgent, setIsUrgent] = useState<boolean>(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [bmwCustomIdKeyword, setBmwCustomIdKeyword] = useState("")
  const [summaryPublishingText, setSummaryPublishingText] = useState("")
  const [spotifyEditorialPitching, setSpotifyEditorialPitching] = useState<SpotifyEditorialPitching>({
    genre: [],
    mood: [],
    style: [],
    instrumentation: [],
    description: ""
  })

  const params = useParams()
  const location = useLocation()

  useScript("//lyricfind.music.ai/loader/v1.js")

  const navigate = useNavigate()

  const defaultProductImage = require("../../../ui/img/default-product.jpeg")

  useEffect(() => {
    if (window.LyricFind) {
      const widget = window.LyricFind.initialize({
        license: "20629d84-3267-4f97-aa78-9b5fa83f7a09"
      })
      setLyricsWidget(widget)
    }
  }, [window.LyricFind])

  const fetchProduct = (productId: string) => {
    setLoading(true)
    releases(`/release/label_camp_product_get/?product_id=${productId}`, "get").then((resp) => {
      setReleaseForm({ ...resp.data.result })
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchUserData()
    releases(`/role/`, "get").then((resp) => {
      setTrackShareHolderRoleOptions(
        resp.data.results.map((role) => {
          return { label: role.name, value: role.id }
        })
      )
    })
  }, [])

  useEffect(() => {
    if (!props.user.productionHolder || !props.user.copyrightHolder) {
      toast.warning(lang.catalog.asset_registry.success.production_and_copyright_holder, warningFilledToastOptions)
      navigate("/account/copyright-and-production")
      return
    }

    const userPermissionIds = props.user.permissionUser.map((up) => up?.permission?.id || 0)
    if (userPermissionIds.includes(USER_PERMISSION.UPLOAD_MESAM)) {
      setIsPublishing(true)
      setSummaryPublishingText(lang.catalog.asset_registry.summary_publishing_mesam)
    } else if (userPermissionIds.includes(USER_PERMISSION.UPLOAD_MSG)) {
      setIsPublishing(true)
      setSummaryPublishingText(lang.catalog.asset_registry.summary_publishing_msg)
    }

    if (userPermissionIds.includes(USER_PERMISSION.UPLOAD_BMV)) {
      setBmwCustomIdKeyword(props.user.bmwCustomId)
    }

    releases(`/release/label_camp_options/`, "get").then((resp) => {
      const labelOptions = resp.data["label_options"]
      const productTypeOptions = resp.data["product_type_options"]
      const languageOptions = resp.data["language_options"]
      const metadataLanguageOptions = resp.data["metadata_language_options"]
      const productGenreOptions = resp.data["product_genre_options"]
      const distributorProductSubGenreOptions = resp.data["distributor_product_sub_genre_options"]
      const distributorPriceCodeOptions = resp.data["distributor_price_code_options"]
      const distributorTrackPriceOptions = resp.data["distributor_track_price_options"]
      const roleOptions = resp.data["roles"]
      setOptions({
        labelOptions,
        productTypeOptions,
        languageOptions,
        metadataLanguageOptions,
        productGenreOptions,
        distributorProductSubGenreOptions,
        distributorPriceCodeOptions,
        distributorTrackPriceOptions,
        roleOptions
      })

      if (params.releaseId === "new") {
        const currentYear = new Date().getFullYear()

        setIsAdd(true)
        if (labelOptions?.length === 1) {
          setReleaseForm({
            ...initReleaseForm,
            product: {
              ...releaseForm.product,
              data: {
                ...releaseForm.product.data,
                relationships: {
                  ...releaseForm.product.data.relationships,
                  label: { data: { id: labelOptions[0].value, type: "labels" } }
                },
                attributes: {
                  ...releaseForm.product.data.attributes,
                  "production-line": `${currentYear} ${props.user.productionHolder}`,
                  "copyright-line": `${currentYear} ${props.user.copyrightHolder}`,
                  "production-year": currentYear
                }
              }
            }
          })
        } else {
          setReleaseForm({ ...initReleaseForm })
        }
        setSelectedTab(AssetRegistryPages.GENERAL_INFORMATION)
        setLoading(false)
      } else if (props.release && props.release.id) {
        setLoading(true)
        releases(`/release/label_camp_product_get/?product_id=${props.release.productId}`, "get").then((resp) => {
          setReleaseForm({ ...resp.data.result })
          setSelectedTab(AssetRegistryPages.GENERAL_INFORMATION)
          setLoading(false)
        })
        setAppleDigitalMaster(props.release.appleDigitalMaster)
        setIsUrgent(props.release.urgent)
        releases(`/release/list_spotify_editorial_pitching/?release_id=${props.release.id}`, "get").then((resp) => {
          if (resp.data.result) {
            setSpotifyEditorialPitching(mapToCamelCase(resp.data.result))
          }
        })
      } else if (params.releaseId) {
        const decodedId = hashids.decode(params.releaseId || "").toString()
        selectRelease(undefined, decodedId)
        setLoading(false)
      }
    })
  }, [props.release])

  const onSearchArtist = (search: string) => {
    if (!search) {
      return
    }

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    setTimeoutId(
      // @ts-ignore
      setTimeout(async () => {
        const existedArtistOptions = [...new Set(selectedArtistList)].map((artist) => {
          return { value: artist, label: artist }
        })
        console.log(existedArtistOptions)
        releases(`/artist/search/?value=${search}`, "get")
          .then((resp) => {
            if (resp && resp.data) {
              const options = resp.data.map((option) => {
                return { value: option, label: option }
              })
              setArtistOptions([{ value: search, label: "+ Add New" }, ...options, ...existedArtistOptions])
            } else {
              setArtistOptions([{ value: search, label: "+ Add New" }, ...existedArtistOptions])
            }
          })
          .catch(() => {
            setArtistOptions([{ value: search, label: "+ Add New" }, ...existedArtistOptions])
          })
      }, 1000)
    )
  }

  const saveTrack = (trackToUpdate: LCReleaseFormData) => {
    const updatedTracks = releaseForm.tracks.map((track: LCReleaseFormData) => {
      if (track.data.id === trackToUpdate.data.id) {
        const trackRelationships = {}
        Object.entries(trackToUpdate.data.relationships).forEach(([key, value]) => {
          if (value["data"] && !notAllowedTrackRelationships.includes(key)) {
            trackRelationships[key] = { data: value["data"] }
          }
        })
        const trackAttributes = {}
        Object.entries(trackToUpdate.data.attributes).forEach(([key, value]) => {
          if (value && !notAllowedTrackAttributes.includes(key)) trackAttributes[key] = value
        })

        const requestBody = {
          track: {
            data: {
              id: trackToUpdate.data.id,
              relationships: trackRelationships,
              attributes: trackAttributes,
              type: "tracks"
            }
          }
        }
        releases(`/release/label_camp_track_create/`, "post", requestBody).then((resp) => {
          if (!resp.data.success) {
            setErrorText(JSON.stringify(resp.data.result))
          }
        })
        return trackToUpdate
      }
      return track
    })

    setReleaseForm({
      ...releaseForm,
      tracks: updatedTracks
    })
  }

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const applyArtistToAll = async (key: string, values: any) => {
    setLoading(true)
    for (const track of releaseForm.tracks) {
      track.data.attributes[key] = values
      saveTrack(track)
      await delay(2000) // Wait for 2 seconds
    }
    toast.success(lang.catalog.asset_registry.success.updated_all_tracks, successFilledToastOptions)
    setLoading(false)
  }

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#F0F0F0",
        padding: 10,
        scrollbarWidth: "thin",
        scrollbarColor: "#E7E7E7 #F5F5F5"
      }}
    >
      {loading && <Loading />}
      <ErrorModal text={errorText} setText={setErrorText} />
      <div
        style={{
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          borderRadius: 10,
          boxShadow: "0px 2px 2px 0px lightgray"
        }}
      >
        <div style={{ height: `${headerHeight}px` }}>
          <div
            className="flex pl-10 pr-10"
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/overview")}
              className={`w-auto flex flex-row gap-[8px] justify-start items-center py-[8px] px-[10px] rounded-[10px] group-hover:!bg-[#f0f0f0]`}
            >
              <span className="w-[20px] aspect-square flex justify-center items-center p-0">
                <img className="w-full h-full" src={"/navigation_icons/arrow-left.svg"}></img>
              </span>
              <span className="flex items-center">
                <KSpan text={lang.header.back_to_overview} color="#111" fontWeight={500} fontSize={14} />
              </span>
            </div>
            <div style={{ textAlign: "left", display: "inline-block" }}>
              <KLogo width={48} height={48} primaryTextVisible={true} logoColor="white" borderRadius={1} />
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {}}
              className={`w-auto flex flex-row gap-[8px] justify-start items-center py-[8px] px-[10px] rounded-[10px] group-hover:!bg-[#f0f0f0]`}
            >
              <span className="flex items-center gap-4">
                {(props.user.roleId === USER_ROLE.SuperAdmin || props.user.roleId === USER_ROLE.Admin) && (
                  <KButton
                    onClick={() => {
                      navigate(`${location.pathname.replace("asset-registry", "release-registry")}`)
                    }}
                    text={lang.catalog.asset_registry.switch_ui}
                    background="transparent"
                    shadowDisabled={true}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
        <div style={{ height: `calc(100% - ${headerHeight + navigationFlowHeight}px)`, overflowY: "scroll" }}>
          <div className={"bg-white flex p-4 shadow-amber-600"} style={{ paddingLeft: 32 }}>
            <div>
              <img
                style={{ borderRadius: "5px" }}
                width={50}
                src={releaseForm.product.data.attributes["cover-url"] || defaultProductImage}
                alt={"table_image"}
              />
            </div>
            <div className={"ml-5"}>
              <KTitleSpan text={releaseForm.product.data.attributes.name} fontSize={16} lineHeight="auto" />
              <KSpan text={releaseForm.product.data.attributes.artist?.join(", ") || "-"} />
            </div>
          </div>
          {selectedTab === AssetRegistryPages.GENERAL_INFORMATION && (
            <GeneralInformation
              releaseForm={releaseForm}
              setReleaseForm={setReleaseForm}
              options={options}
              setSelectedTab={setSelectedTab}
              setErrorText={setErrorText}
              artistOptions={artistOptions}
              setArtistOptions={setArtistOptions}
              onSearchArtist={onSearchArtist}
              selectedArtistList={selectedArtistList}
              setSelectedArtistList={setSelectedArtistList}
              applyArtistToAll={applyArtistToAll}
              appleDigitalMaster={appleDigitalMaster}
              setAppleDigitalMaster={setAppleDigitalMaster}
              user={props.user}
              isUrgent={isUrgent}
              setIsUrgent={setIsUrgent}
            />
          )}
          {selectedTab === AssetRegistryPages.TRACKS_AND_ASSETS && (
            <TracksAndAssets
              releaseForm={releaseForm}
              setReleaseForm={setReleaseForm}
              options={options}
              setSelectedTab={setSelectedTab}
              setErrorText={setErrorText}
              artistOptions={artistOptions}
              setArtistOptions={setArtistOptions}
              onSearchArtist={onSearchArtist}
              selectedArtistList={selectedArtistList}
              setSelectedArtistList={setSelectedArtistList}
              lyricsWidget={lyricsWidget}
              setNavigationZIndex={setNavigationZIndex}
              applyArtistToAll={applyArtistToAll}
              trackShareHolderRoleOptions={trackShareHolderRoleOptions}
              user={props.user}
              isPublishing={isPublishing}
              saveTrack={saveTrack}
            />
          )}
          {selectedTab === AssetRegistryPages.TERRITORY_AND_PLATFORM_RIGHTS && (
            <TerritoryAndPlatformRights
              releaseForm={releaseForm}
              setReleaseForm={setReleaseForm}
              setSelectedTab={setSelectedTab}
              setErrorText={setErrorText}
              options={options}
            />
          )}
          {selectedTab === AssetRegistryPages.EDITORIAL_PITCHING && (
            <SpotifyEditorialPitchingPage
              releaseForm={releaseForm}
              spotifyEditorialPitching={spotifyEditorialPitching}
              setSpotifyEditorialPitching={setSpotifyEditorialPitching}
              setSelectedTab={setSelectedTab}
              setErrorText={setErrorText}
            />
          )}
          {selectedTab === AssetRegistryPages.SUMMARY && (
            <Summary
              releaseForm={releaseForm}
              setReleaseForm={setReleaseForm}
              options={options}
              setSelectedTab={setSelectedTab}
              fetchProduct={fetchProduct}
              setErrorText={setErrorText}
              isPublishing={isPublishing}
              summaryPublishingText={summaryPublishingText}
              bmwCustomIdKeyword={bmwCustomIdKeyword}
            />
          )}
        </div>
        <div style={{ height: `${navigationFlowHeight}px` }}>
          <NavigationFlow
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            navigationZIndex={navigationZIndex}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    release: state.catalogData.selectedRelease
  }
}

export default connect(mapStateToProps, { selectRelease, fetchUserData })(AssetRegistry)
